import React, { FC, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ITask from "../../models/ITask";
import Editor from "@monaco-editor/react";
import "./task-item.css";
import FirstTab, { Comment } from "../task/components/first-tab";
import SecondTab, { ResultDivInfo } from "../task/components/second-tab";
import { useAppDispatch } from "../../hooks";

import { Buffer } from "buffer";
import { ENCODE_TYPE } from "../../constants";
import {
    ICodeInterpertResult,
    useLazyGetCodeInterpertResultQuery,
    useLazyGetSolutionsQuery,
    useLazyGetTaskQuery,
    useLazyGetTasksQuery,
    useSubmitCheckCodeMutation,
    useSubmitSolutionMutation,
} from "../../services/task-services";

import { useSelector } from "react-redux";
import Tabs, { Tab, TabContent } from "../../components/tabs/tabs";
import styled from "styled-components";
import { setSubmitCheckCodeIds, setSubmitTaskId } from "../../slices/slices";
import { toast } from "react-toastify";
import CheckIcon from "../../components/icons/check-icon";
import TasksHeader from "../../components/header/header-tasks";
import { Button } from "@ui/ui-components";
import { codeEditorLangIDMap } from "../../constants/languages-id";
import Loading from "../../components/loading/loading";

const StyledButton = styled(Button)`
    padding: 0 50px !important;
`;

const TabPanelFirst = styled(TabContent)`
    position: relative;
    height: 706px;
`;

type langKey = keyof typeof codeEditorLangIDMap;

const StyledPre = styled.pre`
    background: rgb(204 204 204 / 18%);
    padding: 20px;
    margin: 20px 0;
    border-radius: 6px;

    code {
        white-space: break-spaces;
        word-break: break-word;
    }
`;
const ConsoleTabContent: FC<{
    isFetching: boolean;
    taskDescription: string;
    result: ICodeInterpertResult | undefined;
}> = ({ taskDescription, result, isFetching }) => {
    if (isFetching)
        return (
            <>
                <ResultDivInfo>Код исполянется...</ResultDivInfo>
                <Loading />
            </>
        );

    if (!result) {
        return <></>;
    }

    return (
        <div>
            {!!taskDescription.length && <Comment dangerouslySetInnerHTML={{ __html: taskDescription }} />}
            <br />
            <p>Результат исполнения: </p>
            <p>Статус: {result.status}</p>
            <StyledPre>
                <code>
                    <p>{result.compileOutput}</p>
                    <p>{result.stderr}</p>
                    <p>{result.message}</p>
                    <p>{result.stdin}</p>
                    <p>{result.stdout}</p>
                </code>
            </StyledPre>
        </div>
    );
};

const TaskItem = () => {
    const [getTasks, { data: tasks = [] }] = useLazyGetTasksQuery();
    const [getSolutions, { data: solutions = [] }] = useLazyGetSolutionsQuery();
    const [getCodeInterpretResult] = useLazyGetCodeInterpertResultQuery();

    const [getTask, { data: task }] = useLazyGetTaskQuery();

    const [submitSolution, { isLoading: isLoadingSubmit }] = useSubmitSolutionMutation();
    const [submitCodeToCheck] = useSubmitCheckCodeMutation();
    const { id } = useParams();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [value, setValue] = useState(0);
    const [editorValue, setEditorValue] = useState("");
    const [nextTask, setNextTask] = useState<ITask | null>(null);
    const [prevTask, setPrevTask] = useState<ITask | null>(null);
    const [languageId, setLanguageId] = useState<number>(0);
    const [languageTitle, setLanguageTitle] = useState<string>("java");
    const [checkCodeResult, setCheckCodeResult] = useState<ICodeInterpertResult | undefined>();
    const [codeCheckResultsIsFetching, setCodeCheckResultsIsFetching] = useState(false);
    const handleChange = (newValue: number) => {
        setValue(newValue);
    };
    const eventId = useSelector((state: any) => state.reducers.eventId);
    const collectionId = useSelector((state: any) => state.reducers.collectionId);
    const submitTaskId = useSelector((state: any) => state.reducers.submitTaskId);
    const submitCheckCodeIds = useSelector((state: any) => state.reducers.submitCheckCodeIds);

    const checkCodeInterval = useRef<ReturnType<typeof setInterval> | undefined>();

    const getCheckCodeResultFN4Interval = async (id: number) => {
        getCodeInterpretResult({ id }).then(r => {
            if (r.data?.status !== "Processing") {
                clearInterval(checkCodeInterval.current);
                checkCodeInterval.current = undefined
                setCheckCodeResult(s => s = r.data);
                setCodeCheckResultsIsFetching(s => s = false);
            }
        });
    };

    useEffect(() => {
        if (task) {
            if (task.simpleCodes.length > 0) {
                setEditorValue(task.simpleCodes[0].code);
            }
            if (task.languages.length > 0) {
                setLanguageId(task.languages[0].id);
                setLanguageTitle(task.languages[0].title.toLowerCase());
            }
        }
    }, [task]);

    useEffect(() => {
        let interval: ReturnType<typeof setInterval>;
        setCheckCodeResult(undefined);

        if (id) {
            interval = setInterval(() => getSolutions(id), 5000);
            if (submitCheckCodeIds && submitCheckCodeIds[id]) {
                checkCodeInterval.current = setInterval(() => getCheckCodeResultFN4Interval(submitCheckCodeIds[id]), 1_000);
            }
        }

        return () => {
            clearTimeout(interval);
            clearTimeout(checkCodeInterval.current);
            checkCodeInterval.current = undefined
        };
        //     TODO: Разобраться с этой проблемой
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, getSolutions]);

    useEffect(() => {
        getTasks(collectionId);
    }, [getTasks, collectionId]);

    useEffect(() => {
        if (id && tasks.length > 0) {
            const currentTaskIndex = tasks.findIndex(e => {
                if (e.id === parseInt(id)) {
                    return e;
                }
                return false;
            });
            if (currentTaskIndex === -1) {
                navigate(-1);
            } else {
                const nextTaskIndex = currentTaskIndex + 1;
                setNextTask(tasks[nextTaskIndex]);
                if (currentTaskIndex === 0) {
                    setPrevTask(tasks[currentTaskIndex]);
                } else {
                    setPrevTask(tasks[currentTaskIndex - 1]);
                }
                getTask(id);
            }
        }
    }, [id, navigate, tasks, getTask]);

    const handleSubmitCodeToCheck = async () => {
        if (!task || !id) return;
        if (!editorValue) {
            toast.dark("Не заполнен код");
            return false;
        }

        clearInterval(checkCodeInterval.current);
        checkCodeInterval.current = undefined

        const encodedbase64 = Buffer.from(editorValue).toString(ENCODE_TYPE);

        try {
            const { id: runCodeQueueID } = await submitCodeToCheck({
                language_id: languageId,
                source_code: encodedbase64,
                taskId: Number(id),
                collectionId: collectionId,
                offerId: eventId,
                testCaseId: task.testIdTemplate,
            }).unwrap();

            dispatch(setSubmitCheckCodeIds({ ...submitCheckCodeIds, [id as keyof typeof submitCheckCodeIds]: runCodeQueueID }));
            setCodeCheckResultsIsFetching(s => s = true);
            setValue(2);
            toast("Код отправлен", { icon: <CheckIcon /> });

            getCodeInterpretResult({ id: runCodeQueueID }).then(r => {
                if (r.data?.status === "Processing" || r.data?.status === "Started") {
                    checkCodeInterval.current = setInterval(() => getCheckCodeResultFN4Interval(runCodeQueueID), 1_000);
                    return
                }
                setCodeCheckResultsIsFetching(s => s = false);
            });
        } catch (e) {
            toast.dark("Не получилось отправить код на проверку");
            return false;
        }
    };
    const handleSendButton = async () => {
        if (!id) return;
        if (!editorValue) {
            toast.dark("Не заполнен код");
            return false;
        }
        const encodedbase64 = Buffer.from(editorValue).toString(ENCODE_TYPE);

        toast("Код отправлен", { icon: <CheckIcon /> });

        const body = {
            language_id: languageId,
            source_code: encodedbase64,
            taskId: id,
            collectionId: collectionId,
            offerId: eventId,
        };

        submitSolution(body);
        dispatch(setSubmitTaskId({ ...submitTaskId, [id as keyof typeof submitTaskId]: 1 }));
        setValue(1);
    };

    const handleEditorChange = (value: any) => {
        setEditorValue(value);
    };

    if (!id) {
        return null;
    }

    const languageName = codeEditorLangIDMap[languageId as langKey] || "plaintext";

    return (
        <>
            <TasksHeader eventId={eventId} collectionId={collectionId} />
            <div className={"task-item-container"}>
                <div className={"parent-block"}>
                    <div className={"block first"}>
                        <>
                            <Tabs value={value} onChange={handleChange}>
                                <Tab>Задание</Tab>
                                <Tab>Результат</Tab>
                                {/*TODO: пока что тестируем только для SQL*/}
                                {languageName === "sql" && <Tab>Консоль</Tab>}
                            </Tabs>

                            <TabPanelFirst value={value} index={0}>
                                {task ? <FirstTab nextTask={nextTask} prevTask={prevTask} task={task as ITask} tasks={tasks as ITask[]} /> : null}
                            </TabPanelFirst>
                            <TabContent value={value} index={1}>
                                {solutions && <SecondTab solutions={solutions} taskId={id} />}
                            </TabContent>
                            <TabContent index={2} value={value}>
                                <ConsoleTabContent isFetching={codeCheckResultsIsFetching} taskDescription={task?.description || ""} result={checkCodeResult} />
                            </TabContent>
                        </>
                    </div>
                    <div className={"block second"}>
                        <Editor
                            height="85%"
                            width={`95%`}
                            language={languageName}
                            defaultLanguage={languageName}
                            value={editorValue}
                            defaultValue={editorValue}
                            theme={"light"}
                            onChange={handleEditorChange}
                            className={"editor"}
                        />
                        <div style={{ borderTop: "1px solid #DCDCDC", marginTop: 27 }} />
                        <div
                            style={{
                                marginTop: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: 10,
                                position: "absolute",
                                bottom: "10px",
                                right: "10px",
                                width: "100%",
                            }}>
                            <code style={{ marginLeft: "30px" }}>{languageTitle}</code>
                            <div className={"code-editor__actions-btn flex flex-row gap-1"}>
                                {/*TODO: врменно отображаем кнопку только для SQL*/}
                                {languageName === "sql" && <Button label={"Запустить код"} disabled={isLoadingSubmit || codeCheckResultsIsFetching} onClick={handleSubmitCodeToCheck} size={"small"} variant={"outlined"} />}
                                <StyledButton label={"Отправить код на проверку"} disabled={isLoadingSubmit} onClick={handleSendButton} size={"small"} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TaskItem;
