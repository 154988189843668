import React from "react";
import styled from "styled-components";
import StyledButton from "../../components/button/styledButton";
import { Link } from "react-router-dom";

const Box = styled.div`
    background-color: #ffffff;
    border-radius: 20px;
    margin-top: 80px;
`;

const ChildBox = styled.div`
    display: flex;
    gap: 24px;
    justify-content: center;
    align-items: center;
    padding: 178px 282px;
    flex-direction: column;
`;

const StatusIcon = styled.div`
    height: 120px;
    width: 120px;
    border-radius: 100px;
    background-color: #fcf6eb;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const PTitle = styled.p`
    font-size: 50px;
    font-weight: 500;
    line-height: 54px;
    letter-spacing: 0px;
    text-align: center;
`;

const PDescription = styled.p`
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: center;
`;

const StyledLink = styled(Link)`
    width: 100%;
    text-decoration: none;
`;

const TaskComplete = () => {
    return (
        <div className={"main-content"}>
            <Box>
                <ChildBox>
                    <StatusIcon>
                        <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M33 19C33 20.1046 32.1046 21 31 21C29.8954 21 29 20.1046 29 19C29 17.8954 29.8954 17 31 17C32.1046 17 33 17.8954 33 19Z" fill="#DCA437" />
                            <path d="M31 24C32.1046 24 33 24.8954 33 26V43C33 44.1046 32.1046 45 31 45C29.8954 45 29 44.1046 29 43V26C29 24.8954 29.8954 24 31 24Z" fill="#DCA437" />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M62 31C62 48.1208 48.1208 62 31 62C13.8792 62 0 48.1208 0 31C0 13.8792 13.8792 0 31 0C48.1208 0 62 13.8792 62 31ZM58 31C58 45.9117 45.9117 58 31 58C16.0883 58 4 45.9117 4 31C4 16.0883 16.0883 4 31 4C45.9117 4 58 16.0883 58 31Z"
                                fill="#DCA437"
                            />
                        </svg>
                    </StatusIcon>
                    <PTitle>Время закончилось</PTitle>
                    <PDescription>К сожалению, время на выполнение тестового задания закончилось, мы сохранили результаты и отправили на проверку</PDescription>
                    <StyledLink to={"/"}>
                        <StyledButton fullWidth>Вернуться на главную</StyledButton>
                    </StyledLink>
                </ChildBox>
            </Box>
        </div>
    );
};

export default TaskComplete;
