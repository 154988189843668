import React from "react";

const AlertIcon = () => {
  return (
    <svg
      width="64"
      height="59"
      viewBox="0 0 64 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.9998 20.542C33.1044 20.542 33.9998 21.4375 33.9998 22.542V36.942C33.9998 38.0466 33.1044 38.942 31.9998 38.942C30.8953 38.942 29.9998 38.0466 29.9998 36.942V22.542C29.9998 21.4375 30.8953 20.542 31.9998 20.542Z"
        fill="#FF7373"
      />
      <path
        d="M29.9998 43.9627C29.9998 42.8788 30.8785 42.0001 31.9625 42.0001H32.0372C33.1211 42.0001 33.9998 42.8788 33.9998 43.9627V44.0374C33.9998 45.1213 33.1211 46.0001 32.0372 46.0001H31.9625C30.8785 46.0001 29.9998 45.1213 29.9998 44.0374V43.9627Z"
        fill="#FF7373"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.5505 6.28906C37.3277 -1.1009 26.672 -1.10091 22.4491 6.28905L1.95777 42.149C-2.23265 49.4822 3.06239 58.6065 11.5084 58.6065H52.4912C60.9373 58.6065 66.2323 49.4822 62.0419 42.149L41.5505 6.28906ZM25.9221 8.27361C28.6094 3.57091 35.3903 3.57091 38.0775 8.27361L58.5689 44.1335C61.2355 48.8001 57.866 54.6065 52.4912 54.6065H11.5084C6.13368 54.6065 2.76411 48.8001 5.43074 44.1335L25.9221 8.27361Z"
        fill="#FF7373"
      />
    </svg>
  );
};

export default AlertIcon;
