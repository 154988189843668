import React from "react";
import styled from "styled-components";
import ExitButton from "../exit-button/exit-button";
import { useSelector } from "react-redux";
import UserIcon from "../icons/user-icon";
import { BodyMedium16 } from "../title/title";

const StyledHeader = styled.div`
    position: relative;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.07);
`;

const Ul = styled.ul`
    height: inherit;
    list-style-type: none;
    display: flex;
    justify-content: flex-end;
    gap: 50px;
    width: 1200px;
    align-items: center;
`;

const UserContainer = styled.div`
    display: flex;
    gap: 10px;
`;

const Header = () => {
    const username = useSelector((state: any) => state.reducers.username);

    return (
        <StyledHeader>
            <Ul>
                <UserContainer>
                    <UserIcon />
                    <BodyMedium16>{username}</BodyMedium16>
                </UserContainer>
                <ExitButton />
            </Ul>
        </StyledHeader>
    );
};

export default Header;
