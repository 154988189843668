import styled from "styled-components";
import { colors } from "../../../../constants/global-style-variables";
import { Button } from "@ui/ui-components";
import React, { FC, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { collectionDTOList } from "../../api/events";
import { timeFormat } from "../../../../utils/timeFormat";
import StartCollectionModal from "../modal/start-collection-modal";
import { useLazyStartCollectionQuery } from "../../../../services/task-services";
import CrossIcon from "../../../../components/icons/cross-icon";
import { useAppDispatch } from "../../../../hooks";
import { setCollectionId, setEventId, setTitle } from "../../../../slices/slices";
import dayjs, { Dayjs } from "dayjs";

const Container = styled.div`
    margin-top: 40px;
    display: flex;
    gap: 24px;
    flex-direction: column;
`;

const CollectionContainer = styled.div`
    background: linear-gradient(0deg, #ffffff, #ffffff), linear-gradient(360deg, rgba(0, 0, 0, 0.4) -7.1%, rgba(0, 0, 0, 0) 53.7%), #22252d;
    border-radius: 20px;
    padding: 40px;
`;
const CollectionMain = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
`;
const CollectionHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;
const CollectionInfo = styled.div``;
const CollectionButtons = styled.div`
    display: flex;
    gap: 12px;
`;

const TitleText = styled.p`
    font-family: Roboto;
    font-size: 30px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0px;
    text-align: left;
    color: ${colors.text.main};
`;

const Ul = styled.ul`
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const Li = styled.li`
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: ${colors.text.main};
`;

interface ICollectionList {
    collections: collectionDTOList[];
    eventId: string | undefined;
    eventStatus: string;
    eventDateEnd: Dayjs;
}

const CollectionList: FC<ICollectionList> = ({ collections, eventId, eventStatus, eventDateEnd }) => {
    const ref: any = useRef(null);
    const [timeToCompleteText, setTimeToCompleteText] = useState<string | undefined>();
    const [collectionId, setLocalCollectionId] = useState("");
    const [startCollection] = useLazyStartCollectionQuery();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    if (collections.length === 0) {
        return null;
    }

    const startTest = (collection: collectionDTOList) => {
        setLocalCollectionId(collection.id);
        const diffDate = eventDateEnd.diff(dayjs(), "second");
        if (collection?.timeToComplete) {
            if (diffDate >= collection.timeToComplete) {
                setTimeToCompleteText(timeFormat(collection?.timeToComplete));
            } else {
                setTimeToCompleteText(timeFormat(diffDate));
            }
        }

        ref.current && ref.current.showModal();
    };

    const handleConfirmStartTest = async () => {
        try {
            if (eventId) {
                await startCollection({ eventId, collectionId }).unwrap();
                dispatch(setCollectionId(collectionId));
                dispatch(setEventId(eventId));
                const collection = collections.find(collection => collection.id === collectionId);
                if (collection) {
                    dispatch(setTitle(collection.title));
                }
                navigate(`/tasks-list`);
            }
        } catch (e: any) {
            if (e.status !== 403) {
                toast.dark("Ошибка при старте коллекции", { icon: <CrossIcon /> });
            }
            console.warn(e);
        }
    };

    return (
        <Container>
            <StartCollectionModal modalRef={ref} handleConfirmStartTest={handleConfirmStartTest} timeToCompleteText={timeToCompleteText} />
            {collections.map(collection => {
                return (
                    <CollectionContainer key={collection.id}>
                        <CollectionMain>
                            <CollectionHeader>
                                <TitleText>{collection.title}</TitleText>
                                {/* <BadgeContainer>
                                    <Span>Flutter</Span>
                                    <Span>REST API</Span>
                                </BadgeContainer> */}
                            </CollectionHeader>
                            <CollectionInfo>
                                <Ul>
                                    <Li>– Уровень: {collection.level}</Li>
                                    <Li>– Макс. кол-во баллов: {collection.maxScore}</Li>
                                    <Li>– Время на выполнение: {timeFormat(collection.timeToComplete)}</Li>
                                    <Li>– Кол-во задач: {collection.tasksCount}</Li>
                                </Ul>
                            </CollectionInfo>
                            <CollectionButtons>
                                <StartCollectionButton collection={collection} eventStatus={eventStatus} startTest={() => startTest(collection)} />
                            </CollectionButtons>
                        </CollectionMain>
                    </CollectionContainer>
                );
            })}
        </Container>
    );
};

interface IStartCollectionButton {
    startTest: () => void;
    eventStatus: string;
    collection: collectionDTOList;
}

const StartCollectionButton = ({ startTest, eventStatus, collection }: IStartCollectionButton) => {
    let disabled = true;
    let title = "Начать тестирование";

    if (eventStatus.toLowerCase() === "in_progress") {
        disabled = false;
    }

    if (eventStatus.toLowerCase() === "new") {
        return null;
    }

    if (collection.status.toLowerCase() === "not_registered") {
        disabled = true;
        title = "Вы не зарегистрированы на данную вакансию";
    }

    if (collection.status.toLowerCase() === "completed") {
        disabled = true;
        title = "Вы уже прошли данную вакансию";
    }

    return <Button title={title} onClick={startTest} style={{ fontFamily: "Roboto" }} label={title} disabled={disabled} />;
};

export default CollectionList;
