import { Outlet } from "react-router-dom";
import React from "react";

import "./main-layout_.css";
import styled from "styled-components";
import Header from "../../components/header/header";

const Main = styled.main`
    display: flex;
    flex-direction: column;
    position: relative;
`;

const Container = styled.div`
    margin: 0 auto;
    display: grid;
    grid-auto-columns: minmax(auto, 1200px);
    padding-bottom: 100px;
`;

export default function Layout() {
    return (
        <>
            <Main>
                <Header />
                <Container>
                    <Outlet />
                </Container>
            </Main>
        </>
    );
}
