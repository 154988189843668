import React from "react";
import styled, { keyframes } from "styled-components";

const LoadingDiv = styled.div`
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    div:nth-child(1) {
        animation-delay: -0.45s;
    }
    div:nth-child(2) {
        animation-delay: -0.3s;
    }
    div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const fadeIn = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const ChildDiv = styled.div`
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 3px solid #d35b5b;
    border-radius: 50%;
    animation: ${fadeIn} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #2ba9d0 transparent transparent transparent;
`;

const LoadingMain = styled.div<{ fullHeight: boolean | undefined }>`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: ${(props: { fullHeight?: boolean }) => (props.fullHeight ? "100vh" : "500px")};
`;

interface Props {
    fullHeight?: boolean;
}

const Loading = (props: Props) => {
    const { fullHeight } = props;

    return (
        <LoadingMain fullHeight={fullHeight}>
            <LoadingDiv className="lds-ring">
                <ChildDiv></ChildDiv>
                <ChildDiv></ChildDiv>
                <ChildDiv></ChildDiv>
                <ChildDiv></ChildDiv>
            </LoadingDiv>
        </LoadingMain>
    );
};

export default Loading;
