import React, { Suspense } from "react";
import "./App.css";

import { Link, Outlet, Route, Routes, useLocation } from "react-router-dom";

import Login from "./pages/login/login";
import TaskItem from "./pages/task/task-item";
import Activation from "./pages/activation/activation";

import MainLayout from "./layouts/main/main-layout";
import TasksList from "./pages/tasks-list/tasks-list";
import TaskComplete from "./pages/task-complete/task-complete";
import { useAuth } from "./hooks/use-auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import Loading from "./components/loading/loading";
import Register from "./pages/register/register";
import CrossIcon from "./components/icons/cross-icon";
import Events from "./pages/events/events";
import Event from "./pages/events/event";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

const StyledToast = styled(ToastContainer)`
    // success
    .Toastify__toast-theme--light {
        background-color: #60ae5e;
        color: #ffffff;
        border-radius: 12px;
    }
    // error
    .Toastify__toast-theme--dark {
        background-color: #ff7373;
        color: #ffffff;
        border-radius: 12px;
    }

    .Toastify__toast--warning {
        background-color: #ffd273;
        color: #333;
        border-radius: 12px;
    }
`;

const ResetPasswordPage = React.lazy(() => import("./pages/reset-password/"));

function App() {
    const auth = useAuth();
    const location = useLocation();
    dayjs.locale("ru");
    dayjs.extend(relativeTime);

    if (location.pathname === "/register" || location.pathname === "/register/") {
        return (
            <>
                <Register />
                <StyledToast closeButton={false} hideProgressBar={true} icon={<CrossIcon />} position={"bottom-right"} />
            </>
        );
    }

    if (!auth.token && !location.pathname.startsWith("/reset-password")) {
        return (
            <>
                <Login />

                <StyledToast closeButton={false} hideProgressBar={true} icon={<CrossIcon />} position={"bottom-right"} />
            </>
        );
    }

    return (
        <Suspense fallback={<Loading fullHeight={true} />}>
            <Routes>
                <Route element={<Layout />}>
                    <Route element={<MainLayout />}>
                        <Route path="/" element={<Events />} />
                        <Route path="/events" element={<Events />} />
                        <Route path="/event/:eventId" element={<Event />} />
                    </Route>
                    <Route path="/tasks-list" element={<TasksList />} />
                    <Route path="/task/:id" element={<TaskItem />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/task-complete" element={<TaskComplete />} />
                    <Route path="/reset-password" element={<ResetPasswordPage />} />
                    <Route path="*" element={<NoMatch />} />
                    <Route path="/activation/:key" element={<Activation />} />
                </Route>
            </Routes>
            <StyledToast closeButton={false} hideProgressBar={true} icon={<CrossIcon />} position={"bottom-right"} />
        </Suspense>
    );
}

function Layout() {
    return (
        <div className={"container"}>
            <Outlet />
        </div>
    );
}

function NoMatch() {
    return (
        <div>
            <h2>Упс! Страница не найдена</h2>
            <p>
                <Link to="/">Вернуться на домашнюю страницу</Link>
            </p>
        </div>
    );
}

export default App;
