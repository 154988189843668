const colors = {
    text: {
        main: "#1F1F1F",
        additional: "#8F8F8F",
        inverse: "#FFFFFF",
    },
    element: {
        fillLight: "#F2F2F2",
        fillDark: "#DCDCDC",
    },
    warning: {
        validation: "#FF0000",
        back: "#FFD9D9",
    },
    action: {
        primary: "#2BA9D0",
        desctructive: "#FF7373",
    },
    icon: {
        main: "#1F1F1F",
        additional: "#8F8F8F",
        light: "#D9D9D9",
    },
    success: {
        back: "#EFF7EF",
        text: "#60AE5E",
        icon: "#60AE5E",
    },
    layer: {
        level1: "#FFFFFF",
    },
    process: {
        back: "#FCF6EB",
        text: "#DCA437",
        icon: "#DCA437",
    },
};

export { colors };
