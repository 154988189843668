import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import ITask from "../../models/ITask";
import { getHeadersWithAuthorization } from "../../requests/Headers";
import ISolutionResponse from "../../models/ISolutionResponse";
import { HTTP_STATUS } from "../../constants";
import ILanguage from "../../models/ILanguage";
import ITemplateCode from "../../models/ITemplateCode";
import { ITasksFilter } from "../../models/ITasksFilter";

interface IInitialState {
  loading: string;
  loadingTemplateCode: string;
  loadingTask: string;
  loadingTaskById: string;
  error: boolean | null;
  templateCode: ITemplateCode;
  solutions: ISolutionResponse[];
  tasks: ITask[];
  task: ITask;
  loadingSolutions: string;
  currentRequestId: undefined;
  errorT: null;
  languages: ILanguage[];
}

const initialState: IInitialState = {
  loading: "idle",
  loadingTemplateCode: "idle",
  loadingTask: "idle",
  loadingTaskById: "idle",
  loadingSolutions: "idle",
  error: null,
  templateCode: {} as ITemplateCode,
  solutions: [],
  tasks: [],
  task: {} as ITask,
  currentRequestId: undefined,
  errorT: null,
  languages: [],
};

export const weekendOffer = createSlice({
  name: "weekendOffer",
  initialState,
  reducers: {
    setSolutionsInitial: (state) => {
      state.solutions = [];
    },
    setLoadingInitial: (state) => {
      state.loading = "idle";
    },
    setLoadingTaskInitial: (state) => {
      state.loadingTask = "idle";
    },
    setLoadingSolutionsInitial: (state) => {
      state.loadingSolutions = "idle";
    },
    setLoadingTaskByIdInitial: (state) => {
      state.loadingTaskById = "idle";
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchTasks.fulfilled, (state, action) => {
      // Add user to the state array
      state.tasks = action.payload;
      state.loadingTask = HTTP_STATUS.FULFILLED;
    });
    builder.addCase(fetchTasks.pending, (state, action) => {
      // Add user to the state array
      state.loadingTask = HTTP_STATUS.PENDING;
    });
    builder.addCase(fetchTasks.rejected, (state, action) => {
      // Add user to the state array
      state.loadingTask = HTTP_STATUS.REJECTED;
    });
    //
    builder.addCase(fetchTasksFilter.fulfilled, (state, action) => {
      // Add user to the state array
      state.tasks = action.payload;
      state.loadingTask = HTTP_STATUS.FULFILLED;
    });
    builder.addCase(fetchTasksFilter.pending, (state, action) => {
      // Add user to the state array
      state.loadingTask = HTTP_STATUS.PENDING;
    });
    builder.addCase(fetchTasksFilter.rejected, (state, action) => {
      // Add user to the state array
      state.loadingTask = HTTP_STATUS.REJECTED;
    });
    // fetchSolutions
    builder.addCase(fetchSolutions.fulfilled, (state, action) => {
      // Add user to the state array
      state.solutions = action.payload;
      state.loadingSolutions = HTTP_STATUS.FULFILLED;
    });
    builder.addCase(fetchSolutions.pending, (state, action) => {
      // Add user to the state array
      state.loadingSolutions = HTTP_STATUS.PENDING;
    });
    builder.addCase(fetchSolutions.rejected, (state, action) => {
      // Add user to the state array
      state.loadingSolutions = HTTP_STATUS.REJECTED;
    });
    //fetchTaskById
    builder.addCase(fetchTaskById.fulfilled, (state, action) => {
      // Add user to the state array
      // @ts-ignore
      state.task = action.payload;
      state.loadingTaskById = HTTP_STATUS.FULFILLED;
    });
    builder.addCase(fetchTaskById.pending, (state, action) => {
      // Add user to the state array
      // @ts-ignore
      state.loadingTaskById = HTTP_STATUS.PENDING;
    });
    builder.addCase(fetchTaskById.rejected, (state, action) => {
      // Add user to the state array
      // @ts-ignore
      state.loadingTaskById = HTTP_STATUS.REJECTED;
    });
    //submitSolutions
    builder.addCase(submitSolutions.fulfilled, (state, action) => {
      // Add user to the state array
      // @ts-ignore
      state.loading = HTTP_STATUS.FULFILLED;
      // state.solutions = action.payload;
    });
    builder.addCase(submitSolutions.pending, (state, action) => {
      // Add user to the state array
      // @ts-ignore
      state.loading = HTTP_STATUS.PENDING;
    });
    builder.addCase(submitSolutions.rejected, (state, action) => {
      // Add user to the state array
      // @ts-ignore
      state.loading = HTTP_STATUS.REJECTED;
    });
    builder.addCase(fetchTemplateCode.fulfilled, (state, action) => {
      // @ts-ignore
      state.templateCode = action.payload;
      state.loadingTemplateCode = HTTP_STATUS.FULFILLED;
    });
    builder.addCase(fetchTemplateCode.pending, (state, action) => {
      state.loadingTemplateCode = HTTP_STATUS.PENDING;
    });
    builder.addCase(fetchTemplateCode.rejected, (state, action) => {
      state.loadingTemplateCode = HTTP_STATUS.REJECTED;
    });
  },
});

export const fetchTasksFilter = createAsyncThunk(
  "tasks/fetchTasksFilter",
  async (data: ITasksFilter, thunkAPI) => {
    const response = await axios.get<ITask[]>(
      process.env.REACT_APP_API_URL + `front/api/tasksForUser`,
      {
        params: data,
        headers: getHeadersWithAuthorization(),
      }
    );
    return response.data;
  }
);

export const fetchTemplateCode = createAsyncThunk(
  "tasks/templateCode",
  async (
    data: { language_id: number; taskUuid: string | undefined },
    thunkAPI
  ) => {
    const { language_id, taskUuid } = data;
    const response = await axios.get<ITemplateCode>(
      process.env.REACT_APP_API_URL +
        `front/api/simple-code?langId=${language_id}&uuid=${taskUuid}`,
      {
        headers: getHeadersWithAuthorization(),
      }
    );
    return response.data;
  }
);

export const submitSolutions = createAsyncThunk(
  "tasks/submitSolutions",
  async (
    data: {
      language_id: number;
      source_code: string;
      taskUuid: string | undefined;
      weekendUuid: string | null;
    },
    thunkAPI
  ) => {
    const response = await axios.post<ISolutionResponse[]>(
      process.env.REACT_APP_API_URL + `front/api/solutions?submit=true`,
      data,
      {
        headers: getHeadersWithAuthorization(),
      }
    );
    return response.data;
  }
);
export const fetchTaskById = createAsyncThunk(
  "tasks/fetchTaskById",
  async (taskId: string | undefined, thunkAPI) => {
    const response = await axios.get<ITask>(
      process.env.REACT_APP_API_URL + `front/api/tasks/${taskId}`,
      {
        headers: getHeadersWithAuthorization(),
      }
    );
    return response.data;
  }
);

export const fetchTasks = createAsyncThunk(
  "tasks/fetchTasks",
  async (uuid: string, thunkAPI) => {
    const response = await axios.get<ITask[]>(
      process.env.REACT_APP_API_URL + `front/api/weekend-offers?uuid=${uuid}`,
      {
        headers: getHeadersWithAuthorization(),
      }
    );
    return response.data;
  }
);

export const fetchSolutions = createAsyncThunk(
  "tasks/fetchSolutions",
  async (taskId: string | undefined, thunkAPI) => {
    const response = await axios.get<ISolutionResponse[]>(
      process.env.REACT_APP_API_URL +
        `front/api/solutionByTask?task_id=${taskId}&onlyLastAccepted=true`,
      {
        headers: getHeadersWithAuthorization(),
      }
    );
    return response.data;
  }
);

// Action creators are generated for each case reducer function
export const {
  setLoadingInitial,
  setLoadingTaskInitial,
  setLoadingSolutionsInitial,
  setLoadingTaskByIdInitial,
  setSolutionsInitial,
} = weekendOffer.actions;

export default weekendOffer.reducer;
