import React, { FC } from "react";
import { Button, Modal, ModalFooter, ModalSection } from "@ui/ui-components";
import AlertIcon from "../../../../components/icons/alert-icon";
import styled from "styled-components";
import { colors } from "../../../../constants/global-style-variables";
import { SubheadingRegular24 } from "../../../../components/title/title";

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 16px;
`;

const Span = styled.span`
    font-family: Roboto;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: center;
`;

const AlertContainer = styled.div`
    height: 120px;
    width: 120px;
    border-radius: 100px;
    background: ${colors.warning.back};
    display: flex;
    justify-content: center;
    align-items: center;
`;

interface Props {
    modalRef: any;
    handleConfirmStartTest: () => void;
    timeToCompleteText: string | undefined;
}

const StartCollectionModal: FC<Props> = ({ modalRef, handleConfirmStartTest, timeToCompleteText }) => {
    const handleClose = () => {
        modalRef.current && modalRef.current.close();
    };
    return (
        <Modal ref={modalRef}>
            <ModalSection>
                <AlertContainer>
                    <AlertIcon />
                </AlertContainer>
                <SubheadingRegular24>
                    У вас будет <Span>{timeToCompleteText}</Span> на прохождение тестирования. Отсчет времени начинается после вашего согласия
                </SubheadingRegular24>
                <SubheadingRegular24>Если вы выйдете или закроете страницу, тест будет автоматически завершен, а результаты отправлены на проверку</SubheadingRegular24>
                <SubheadingRegular24>Вы действительно хотите начать тестирование?</SubheadingRegular24>
            </ModalSection>
            <ModalFooter>
                <ButtonContainer>
                    <Button onClick={handleClose} fullWidth label={"Отменить"} variant={"outlined"} />
                    <Button onClick={handleConfirmStartTest} fullWidth label={"Да, начать"} />
                </ButtonContainer>
            </ModalFooter>
        </Modal>
    );
};

export default StartCollectionModal;
