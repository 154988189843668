import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { BodyMedium16 } from "../title/title";

const ExitButtonStyled = styled.button`
    margin-right: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 4px 4px 4px 16px;
    border-radius: 8px;
    background-color: #ededed;
    outline: none;
    border: none;
    width: 106px;
    height: 40px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
`;

const P = styled(BodyMedium16)`
    flex: auto;
`;

const SvgDiv = styled.div`
    width: 26px;
`;

const ExitButton = () => {
    const navigate = useNavigate();

    const handleExit = () => {
        localStorage.clear();
        navigate("/");
        window.location.reload();
    };

    return (
        <ExitButtonStyled onClick={handleExit}>
            <P>Выйти</P>
            <SvgDiv>
                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M4.5 18H11.5C12.6046 18 13.5 17.1046 13.5 16V14C13.5 13.4477 13.9477 13 14.5 13C15.0523 13 15.5 13.4477 15.5 14V16C15.5 18.2091 13.7091 20 11.5 20H4.5C2.29086 20 0.5 18.2091 0.5 16V4C0.5 1.79086 2.29086 0 4.5 0H11.5C13.7091 0 15.5 1.79086 15.5 4V6C15.5 6.55228 15.0523 7 14.5 7C13.9477 7 13.5 6.55228 13.5 6V4C13.5 2.89543 12.6046 2 11.5 2H4.5C3.39543 2 2.5 2.89543 2.5 4V16C2.5 17.1046 3.39543 18 4.5 18Z"
                        fill="#1F1F1F"
                    />
                    <path
                        d="M7.5 10C7.5 10.5523 7.94771 11 8.5 11L18.5858 11L17.2929 12.2929C16.9024 12.6834 16.9024 13.3166 17.2929 13.7071C17.6834 14.0976 18.3166 14.0976 18.7071 13.7071L21.7071 10.7071C22.0976 10.3166 22.0976 9.68342 21.7071 9.29289L18.7071 6.29289C18.3166 5.90237 17.6834 5.90237 17.2929 6.29289C16.9024 6.68342 16.9024 7.31658 17.2929 7.70711L18.5858 9L8.5 9C7.94772 9 7.5 9.44771 7.5 10Z"
                        fill="#1F1F1F"
                    />
                </svg>
            </SvgDiv>
        </ExitButtonStyled>
    );
};

export default ExitButton;
