import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useStartEventMutation, useLazyGetEventByIdQuery } from "./api/events";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import styled from "styled-components";
import { colors } from "../../constants/global-style-variables";
import CollectionList from "./components/event/collection-list";
import Header from "./components/event/header";
import { Tab, Tabs } from "@ui/ui-components";
import { TabContent } from "../../components/tabs/tabs";
import Loading from "../../components/loading/loading";
import dayjs from "dayjs";

const TabContainer = styled.div`
    margin-top: 40px;
`;

const TabsContainer = styled.div`
    margin-top: 40px;
`;

const DescriptionText = styled.p`
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    white-space: break-spaces;
`;

const LinkContainer = styled.div`
    margin-top: 20px;
`;

const LinkUrl = styled.a`
    text-decoration: none;
    font-family: Roboto Flex;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    color: ${colors.action.primary};
`;

const Event = () => {
    const { eventId } = useParams();
    const [getEvent, {data, isLoading}] = useLazyGetEventByIdQuery();
    const [startEvent] = useStartEventMutation();
    const date = new Date();
    const [value, setValue] = useState<number>(0);

    useEffect(() => {
        if (eventId) {
            getEvent(eventId);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventId]);

    if (isLoading) {
        return <Loading />;
    }

    if (!data) {
        return <>Нет данных</>;
    }

    if (!eventId) {
        return <>Нет данных</>;
    }

    const breadcrumb = [
        { to: "/", name: "Главная" },
        { to: "", name: data.title },
    ];

    const handleChange = (e: number) => {
        setValue(e);
    };

    const handleStartEvent = async () => {
        try {
            await startEvent(eventId).unwrap();
        } catch (e) {
            console.warn(e);
        }
    };

    return (
        <>
            <Breadcrumbs child={breadcrumb} />
            <Header data={data} handleStartEvent={handleStartEvent} eventDateStatus={dayjs(date).isBefore(data.endDate) && dayjs(date).isAfter(data.startDate)} />
            <TabContainer>
                <Tabs value={value} onChange={handleChange}>
                    <Tab>Описание</Tab>
                </Tabs>
                <TabContent value={value} index={0}>
                    <TabsContainer>
                        <Description comment={data.comment} url={data.url} />
                        <CollectionList
                            collections={data.collectionDTOList}
                            eventId={eventId}
                            eventDateEnd={dayjs(data.endDate)}
                            eventStatus={data.recruitingStatus}
                        />
                    </TabsContainer>
                </TabContent>
                {/*<TabContent value={value} index={1}>*/}
                {/*    <TasksCompleted dataInactive={dataFinished} />*/}
                {/*</TabContent>*/}
            </TabContainer>
        </>
    );
};

const Description = ({ comment, url }: { comment: string; url: string }) => {
    return (
        <>
            <DescriptionText>{comment}</DescriptionText>
            {url && (
                <LinkContainer>
                    <LinkUrl target={"_blank"} href={url}>
                        Памятка по тестированию
                    </LinkUrl>
                </LinkContainer>
            )}
        </>
    );
};

export default Event;
