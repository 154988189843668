import { apiSlice } from "../api/apiSlice";

export interface RegisterRequest {
    lastName: string;
    firstName: string;
    email: string;
    password: string;
    phoneNumber: string;
    login: string;
    repeatPassword: string;
}

export const RegisterApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        register: builder.mutation<string, Omit<RegisterRequest, "repeatPassword">>({
            query: body => {
                return {
                    url: `api/register`,
                    method: "POST",
                    body,
                };
            },
        }),
    }),
});

export const { useRegisterMutation } = RegisterApi;
