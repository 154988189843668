import React, { useEffect } from "react";
import styled from "styled-components";
import StyledButton from "../../components/button/styledButton";
import { Link, Navigate } from "react-router-dom";
import { useLazyGetTasksQuery } from "../../services/task-services";
import ITask from "../../models/ITask";
import { difficulties } from "../../constants/difficulties";

import { HeadingMedium30, HeadingMedium50 } from "../../components/title/title";
import TasksHeader from "../../components/header/header-tasks";
import { useAppSelector } from "../../hooks";

const MainBlock = styled.div`
    max-width: 1200px;
    margin: 109px auto;
    display: flex;
    flex-direction: column;
`;

const TaskDiv = styled.div`
    background-color: #ffffff;
    border-radius: 20px;
    margin-bottom: 24px;
    position: relative;
`;

export const BadgeDiv = styled.div`
    &:last-child {
        margin: 0;
    }
    margin-right: 16px;
    width: fit-content;
    padding: 8px 12px 8px 12px;
    background-color: #f2f2f2;
    border-radius: 12px;
`;

export const ParentDivBadge = styled.div`
    display: flex;
`;

const ChildBlock = styled.div`
    display: flex;
    gap: 24px;
    padding: 40px;
`;

const Description = styled.div`
    font-family: Roboto Flex, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    blockquote {
        border-left: 5px solid #ccc;
        font-style: italic;
        margin-left: 0;
        margin-right: 0;
        overflow: hidden;
        padding-left: 1.5em;
        padding-right: 1.5em;
    }
`;

const Number = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 100px;
    border: 1px solid #8f8f8f;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const NumberP = styled.p`
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    color: #8f8f8f;
`;

const LeftBlock = styled.div``;
const MiddleBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
const RightBlock = styled.div`
    margin-left: auto;
`;

const TasksList = () => {
    const eventId = useAppSelector((state: any) => state.reducers.eventId);
    const collectionId = useAppSelector((state: any) => state.reducers.collectionId);
    const [getTasks, { data = [] }] = useLazyGetTasksQuery();

    // const data: ITask[] = [
    //     {
    //         id: 1,
    //         uuid: "",
    //         guid: "",
    //         title: "some title",
    //         description: "lorem",
    //         onlyCollection: false,
    //         score: 100,
    //         tags: [{ id: 1, title: "Алгоритмы" }],
    //         languages: [{ id: 1, title: "java" }],
    //         collectionId: 101,
    //         difficult: "",
    //         acceptedSolution: false,
    //     },
    // ];

    // useEffect(() => {
    //     if (woStatus.toLowerCase() !== "new") {
    //         navigate("/");
    //     }
    // }, [woStatus, navigate]);
    //
    useEffect(() => {
        if (collectionId) {
            getTasks(collectionId);
        }
    }, [getTasks, collectionId]);

    if (!collectionId || !eventId) {
        return <Navigate to={"/"} />;
    }

    const Main = () => {
        return (
            <>
                <TasksHeader eventId={eventId} collectionId={collectionId} />
                <MainBlock>
                    <HeadingMedium50 style={{ marginBottom: 48 }}>Список задач</HeadingMedium50>
                    {data &&
                        data.length > 0 &&
                        data.map((task: ITask, index: number) => {
                            const htmlString = task?.description.length > 256 ? `${task?.description.substring(0, 256)}...` : task?.description;
                            return (
                                <TaskDiv key={task.id}>
                                    <ChildBlock>
                                        <LeftBlock>
                                            <Number>
                                                <NumberP>{++index}</NumberP>
                                            </Number>
                                        </LeftBlock>
                                        <MiddleBlock>
                                            <ParentDivBadge>
                                                <BadgeDiv>{task.score} баллов</BadgeDiv>
                                                {task.difficult in difficulties ? <BadgeDiv>{difficulties[task.difficult as keyof typeof difficulties]}</BadgeDiv> : null}
                                            </ParentDivBadge>
                                            <HeadingMedium30>{task.title}</HeadingMedium30>
                                            <Description dangerouslySetInnerHTML={{ __html: htmlString }} />
                                        </MiddleBlock>
                                        <RightBlock>
                                            <Link style={{ cursor: "none", textDecoration: "none" }} to={`/task/${task.id}`}>
                                                <StyledButton status={task.acceptedSolution}>{task.acceptedSolution ? "Выполнено" : "Выполнить"}</StyledButton>
                                            </Link>
                                        </RightBlock>
                                    </ChildBlock>
                                </TaskDiv>
                            );
                        })}
                </MainBlock>
            </>
        );
    };

    const Content = () => {
        // if (isLoadingGetData) {
        //     return <Loading />;
        // }
        //
        // if (isError) {
        //     return <Navigate to={"/"} />;
        // }

        return <Main />;
    };

    return <Content />;
};

export default TasksList;
