import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getHeadersWithAuthorization } from "../../requests/Headers";

interface ILanguages {
  id: number;
  title: string;
  entries: [];
}

export interface ITags {
  id: string;
  title: string;
  entries: [];
}

interface IInitialState {
  difficults: [];
  languages: ILanguages[];
  tags: ITags[];
}

const initialState: IInitialState = {
  difficults: [],
  languages: [],
  tags: [],
};

export const tasksFilter = createSlice({
  name: "tasksFilter",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchDifficult.fulfilled, (state, action) => {
      // Add user to the state array
      state.difficults = action.payload;
    });
    builder.addCase(fetchLanguages.fulfilled, (state, action) => {
      // Add user to the state array
      state.languages = action.payload;
    });
    builder.addCase(fetchTags.fulfilled, (state, action) => {
      // Add user to the state array
      state.tags = action.payload;
    });
  },
});

export const fetchTags = createAsyncThunk(
  "tasks/fetchTags",
  async (thunkAPI) => {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + `front/api/tags`,
      {
        headers: getHeadersWithAuthorization(),
      }
    );
    return response.data;
  }
);

export const fetchLanguages = createAsyncThunk(
  "tasks/fetchLanguages",
  async (thunkAPI) => {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + `front/api/languages`,
      {
        headers: getHeadersWithAuthorization(),
      }
    );
    return response.data;
  }
);

export const fetchDifficult = createAsyncThunk(
  "tasks/fetchDifficult",
  async (thunkAPI) => {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + `front/api/taskDifficulties`,
      {
        headers: getHeadersWithAuthorization(),
      }
    );
    return response.data;
  }
);

// Action creators are generated for each case reducer function
// export const {} = tasksFilter.actions;

export default tasksFilter.reducer;
