import React, { Children, ReactNode } from "react";
import styled from "styled-components";

const Ul = styled.ul`
    list-style-type: none;
    display: flex;
    gap: 10px;
    margin: 0;
    padding: 0;
    flex-basis: auto;
    border-bottom: 1px solid #dcdcdc;
`;

const Li = styled.li<{ active: boolean }>`
    color: ${(props: { active: boolean }) => (props.active ? "#1F1F1F" : "#8F8F8F")};
    padding: 10px;
    cursor: pointer;
    position: relative;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;

    :before {
        transition: all 0.4s linear;
        content: "";
        position: absolute;
        bottom: 0;
        height: 2px;
        background-color: #2ba9d0;
        right: ${(props: { active: boolean }) => (props.active ? "0" : "100%")};
        left: ${(props: { active: boolean }) => (props.active ? "0" : "100%")};
    }
`;

export const StyledTabContent = styled.div`
    padding: 10px 0 0 0;
`;

interface Props {
    children: ReactNode;
    index: number;
    value: number;
    onChange: (e: any) => void;
}

type TabsProps = Omit<Props, "index">;

type TabProps = Pick<Props, "children">;

type TabContentProps = Omit<Props, "onChange">;

const Tabs = ({ children, onChange, value, ...other }: TabsProps): JSX.Element => {
    return (
        <Ul>
            {Children.toArray(children).map((child: any, index) => {
                return (
                    <Li active={value === index} key={index} onClick={() => onChange(index)} {...other}>
                        {child}
                    </Li>
                );
            })}
        </Ul>
    );
};

export const Tab = ({ children }: TabProps): JSX.Element => {
    return <>{children}</>;
};

export const TabContent = ({ children, value, index, ...other }: TabContentProps) => {
    return (
        <StyledTabContent {...other} hidden={value !== index}>
            {value === index && <>{children}</>}
        </StyledTabContent>
    );
};

export default Tabs;
