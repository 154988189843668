import { createSlice } from "@reduxjs/toolkit";

export const slices = createSlice({
    name: "reducers",
    initialState: {
        value: 0,
        submitTaskId: {},
        submitCheckCodeIds: {},
        eventId: "",
        collectionId: "",
        username: "",
        collectionTitle: "",
        title: "",
    },
    reducers: {
        setResetState: state => {
            state.submitTaskId = {};
            state.eventId = "";
            state.collectionId = "";
            state.title = "";
        },
        setTitle: (state, action) => {
            state.title = action.payload;
        },
        setTimer: (state, action) => {
            state.value = action.payload;
        },
        setSubmitTaskId: (state, action) => {
            state.submitTaskId = action.payload;
        },
        setSubmitCheckCodeIds: (state, action) => {
            state.submitCheckCodeIds = action.payload
        },
        setEventId: (state, action) => {
            state.eventId = action.payload;
        },
        setCollectionId: (state, action) => {
            state.collectionId = action.payload;
        },
        setCollectionTitle: (state, action) => {
            state.collectionTitle = action.payload;
        },
        setUsername: (state, action) => {
            state.username = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setTimer, setSubmitTaskId, setCollectionId, setEventId, setUsername, setTitle, setResetState, setSubmitCheckCodeIds } = slices.actions;

export default slices.reducer;
