import React from "react";
import styled from "styled-components";
import { colors } from "../../../constants/global-style-variables";

const Div = styled.div`
    width: 180px;
    border-radius: 8px;
    padding: 12px;
    background-color: ${(props: IProps) => (props.variant === "success" ? colors.success.back : colors.process.back)};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    gap: 10px;
`;

const Title = styled.p<{ variant: string }>`
    //styleName: Button/L-Medium;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    color: ${props => (props.variant === "success" ? colors.success.text : colors.process.text)};
`;

interface IProps {
    variant: "default" | "success";
}

const TaskInfo = (props: IProps) => {
    const { variant } = props;
    return (
        <Div variant={variant}>
            {variant === "success" ? (
                <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.7065 0.292893C18.0971 0.683417 18.0971 1.31658 17.7065 1.70711L6.70655 12.7071C6.51901 12.8946 6.26466 13 5.99944 13C5.73423 13 5.47987 12.8946 5.29234 12.7071L0.578049 7.99282C0.187525 7.6023 0.187525 6.96913 0.578049 6.57861C0.968574 6.18808 1.60174 6.18808 1.99226 6.57861L5.99944 10.5858L16.2923 0.292893C16.6829 -0.0976311 17.316 -0.0976311 17.7065 0.292893Z"
                        fill="#60AE5E"
                    />
                </svg>
            ) : (
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.5 5C11.5 4.44772 11.0523 4 10.5 4C9.94771 4 9.5 4.44772 9.5 5V9.69098C9.5 10.2591 9.821 10.7785 10.3292 11.0326L14.0528 12.8944C14.5468 13.1414 15.1474 12.9412 15.3944 12.4472C15.6414 11.9532 15.4412 11.3526 14.9472 11.1056L11.5 9.38197V5Z"
                        fill="#DCA437"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20.5 10C20.5 15.5228 16.0228 20 10.5 20C4.97715 20 0.5 15.5228 0.5 10C0.5 4.47715 4.97715 0 10.5 0C16.0228 0 20.5 4.47715 20.5 10ZM18.5 10C18.5 14.4183 14.9183 18 10.5 18C6.08172 18 2.5 14.4183 2.5 10C2.5 5.58172 6.08172 2 10.5 2C14.9183 2 18.5 5.58172 18.5 10Z"
                        fill="#DCA437"
                    />
                </svg>
            )}

            <Title variant={variant} className={"info__title"}>
                {variant === "success" ? "Выполнено" : "На проверке"}
            </Title>
        </Div>
    );
};

export default TaskInfo;
