import React from "react";

const UserIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 0C7.1311 0 5 2.57642 5 5.5C5 7.47721 5.97472 9.29563 7.49317 10.2657C1.97729 11.1828 0 13.9642 0 16V17C0 18.6569 1.34315 20 3 20H17C18.6569 20 20 18.6569 20 17V16C20 13.9642 18.0227 11.1828 12.5068 10.2657C14.0253 9.29563 15 7.47721 15 5.5C15 2.57642 12.8689 0 10 0ZM7 5.5C7 3.45302 8.45062 2 10 2C11.5494 2 13 3.45302 13 5.5C13 7.54698 11.5494 9 10 9C8.45062 9 7 7.54698 7 5.5ZM2 16C2 15.3146 3.04264 12.4032 10 12.0016C16.9574 12.4032 18 15.3146 18 16V17C18 17.5523 17.5523 18 17 18H3C2.44772 18 2 17.5523 2 17V16Z"
                fill="#1F1F1F"
            />
        </svg>
    );
};

export default UserIcon;
