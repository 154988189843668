import React from "react";

const ChevronLeft = () => {
  return (
    <svg
      width="11"
      height="20"
      viewBox="0 0 11 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.707 0.792893C11.0975 1.18342 11.0975 1.81658 10.707 2.20711L2.91406 10L10.707 17.7929C11.0975 18.1834 11.0975 18.8166 10.707 19.2071C10.3164 19.5976 9.68327 19.5976 9.29274 19.2071L1.49985 11.4142C0.718799 10.6332 0.718801 9.36683 1.49985 8.58579L9.29274 0.792893C9.68327 0.402369 10.3164 0.402369 10.707 0.792893Z"
        fill="#8F8F8F"
      />
    </svg>
  );
};

export default ChevronLeft;
