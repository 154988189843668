import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ChevronRight from "../icons/chevron-right";
import { colors } from "../../constants/global-style-variables";

interface Child {
    to: string;
    name: string;
    state?: unknown;
}

interface Props {
    child: Child[];
}

const StyledLink = styled(Link)`
    text-decoration: none;
    color: inherit;
`;

const Ul = styled.ul`
    display: inline-flex;
    list-style-type: none;
    justify-content: center;
    align-items: center;
    gap: 36px;
    color: ${props => colors.text.additional};
`;

const Li = styled.li`
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 36px;
`;

const Main = styled.div`
    padding: 37px 0 37px 0;
`;

const Breadcrumbs = ({ child }: Props) => {
    return (
        <Main>
            <Ul>
                {child.map((e: Child, index: number) => {
                    return (
                        <Li key={index}>
                            {e.to ? (
                                <StyledLink to={e.to} state={e.state}>
                                    {e.name}
                                </StyledLink>
                            ) : (
                                <>{e.name}</>
                            )}
                            {child.length === index + 1 ? null : <ChevronRight size={"small"} />}
                        </Li>
                    );
                })}
            </Ul>
        </Main>
    );
};

export default Breadcrumbs;
