import React from "react";
import ITask from "../../../models/ITask";
import styled from "styled-components";
import { BadgeDiv, ParentDivBadge } from "../../tasks-list/tasks-list";
import { difficulties } from "../../../constants/difficulties";
import { Link } from "react-router-dom";
import ChevronLeft from "../../../components/icons/chevron-left";
import ChevronRight from "../../../components/icons/chevron-right";

const FirstTabDiv = styled.div`
    padding: 20px;
    border-radius: 20px;
`;

const Title = styled.p`
    font-size: 30px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0px;
    text-align: left;
    margin: 24px 0 0 0;
`;

export const Comment = styled.div`
    height: 452px;
    margin-bottom: 10px;
    font-family: Roboto Flex, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    overflow: auto;
    overflow-y: auto;
    &::-webkit-scrollbar-track {
        border-radius: 10px;
        box-shadow: inset 0 0 1px grey;
    }
    &::-webkit-scrollbar-thumb {
        background: red;
        border-radius: 10px;
    }
    &::-webkit-scrollbar {
        width: 10px;
    }
    blockquote {
        border-left: 5px solid #ccc;
        font-style: italic;
        margin-left: 0;
        margin-right: 0;
        overflow: hidden;
        padding-left: 1.5em;
        padding-right: 1.5em;
    }
    
    figure.image {
        clear: both;
        display: table;
        margin: 0.9em auto;
        min-width: 50px;
        text-align: center;
    }
    
    img {
        max-width: 100%;
        height: auto;
    }
`;

const Footer = styled.div`
    position: absolute;
    bottom: 0px;
    border-top: 1px solid #dcdcdc;
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    width: 100%;
`;

const LeftSide = styled.div`
    display: flex;
    gap: 19px;
`;
const MiddleSide = styled.div`
    width: 20px;
    height: 20px;
    padding: 12px;
    border-radius: 100px;
    color: #8f8f8f;
    border: 1px solid #8f8f8f;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const RightSide = styled.div`
    display: flex;
    gap: 19px;
`;
const PLeft = styled.p`
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
`;

const PRight = styled.p`
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
`;

const StyledLink = styled(Link)`
    color: #8f8f8f;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
`;

interface IProps {
    tasks: ITask[];
    task: ITask;
    nextTask: ITask | null;
    prevTask: ITask | null;
}

const FirstTab = ({ task, nextTask, prevTask, tasks }: IProps) => {
    const htmlString = task?.description;
    const indexTask = tasks.findIndex((e: ITask) => e.id === task.id) + 1;
    return (
        <>
            <FirstTabDiv>
                <ParentDivBadge>
                    {task.score ? <BadgeDiv>{task.score} баллов</BadgeDiv> : null}
                    {task.difficult in difficulties ? <BadgeDiv>{difficulties[task.difficult as keyof typeof difficulties]}</BadgeDiv> : null}
                </ParentDivBadge>
                <Title>{task?.title}</Title>
                <Comment dangerouslySetInnerHTML={{ __html: htmlString }} />
            </FirstTabDiv>
            <Footer>
                <StyledLink to={`/task/${prevTask?.id}`}>
                    <LeftSide>
                        <ChevronLeft />
                        <PLeft>Предыдущая задача</PLeft>
                    </LeftSide>
                </StyledLink>
                <MiddleSide>{indexTask}</MiddleSide>

                {nextTask?.id !== undefined ? (
                    <StyledLink to={`/task/${nextTask?.id}`}>
                        <RightSide>
                            <PRight>Следующая задача</PRight>
                            <ChevronRight />
                        </RightSide>
                    </StyledLink>
                ) : (
                    <StyledLink to={``} onClick={(e: any) => e.preventDefault()}>
                        <RightSide>
                            <PRight>Следующая задача</PRight>
                            <ChevronRight />
                        </RightSide>
                    </StyledLink>
                )}
            </Footer>
        </>
    );
};

export default FirstTab;
