import {getToken} from "../config/LocalStorage";

export const getHeadersWithAuthorization = () => {
    return {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + getToken(),
    };
}
export const getHeadersWithoutAuthorization = () => {
    return {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    };
}