const codeEditorLangIDMap = {
    46: "shell",
    48: "c",
    49: "c",
    50: "c",
    75: "c",
    51: "csharp",
    52: "cpp",
    53: "cpp",
    54: "cpp",
    76: "cpp",
    60: "go",
    62: "java",
    63: "javascript",
    70: "python",
    71: "python",
    72: "ruby",
    74: "typescript",
    78: "kotlin",
    81: "scala",
    82: "sql",
} as const;
export { codeEditorLangIDMap };
