import React, { FC } from "react";

import styled from "styled-components";
import { colors } from "../../../../constants/global-style-variables";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import ChevronRight from "../../../../components/icons/chevron-right";
import { ActiveResponce } from "../../../../services/api-services";
import { BodyRegular16, HeadingMedium30 } from "../../../../components/title/title";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const MainLink = styled(Link)`
    padding: 24px 31px 24px 24px;
    width: 1200px;
    background: #ffffff;
    border-radius: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 114px;
    cursor: pointer;
    transition: box-shadow 0.3s ease-out;
    text-decoration: none;

    &:hover {
        box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.05);
    }
`;

const DateText = styled.p`
    font-family: Roboto Flex;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
    color: ${colors.text.additional};
`;

const DescriptionDiv = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
`;

const LeftBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

interface IEventsList {
    data: ActiveResponce[];
}

interface IEventItemProps {
    title: string;
    startDate: string;
    endDate: string;
    status: string;
    statusText: string;
}

const EventItem: FC<IEventItemProps> = ({ title, endDate, startDate, statusText, status }) => {
    return (
        <>
            <LeftBlock>
                <HeadingMedium30>{title}</HeadingMedium30>
                <DateAndStatus startDate={startDate} endDate={endDate} status={status} />
            </LeftBlock>
            <div>
                <ChevronRight />
            </div>
        </>
    );
};

const EventsList = (props: IEventsList) => {
    const { data } = props;

    return (
        <Container>
            {data?.length &&
                data.map(e => (
                    <MainLink to={`/event/${e.recruitingOfferId}`} key={e.recruitingOfferId}>
                        <EventItem title={e.title} endDate={e.endDate} startDate={e.startDate} statusText={e.status_description} status={e.status} />
                    </MainLink>
                ))}
        </Container>
    );
};

interface IGetStatus {
    status: string;
    text: string;
}

interface IDateAndStatus {
    startDate: string;
    endDate: string;
    status: string;
}

export const DateAndStatus: FC<IDateAndStatus> = ({ endDate, startDate, status }) => {
    const DJSStartDate = dayjs(startDate);
    const DJSEndDate = dayjs(endDate);
    const dateUI = [DJSStartDate.format("DD MMMM YYYY")];
    const relativeTimeText = dayjs().isBefore(DJSStartDate) && dayjs().isBefore(DJSEndDate) ? "Начнётся " + DJSStartDate.fromNow() : "Уже идёт";

    if (DJSEndDate.format("DD MMMM YYYY") !== dateUI[0]) {
        dateUI.push(DJSEndDate.format("DD MMMM YYYY"));
    }
    return (
        <DescriptionDiv>
            <BodyRegular16 color={colors.text.additional}>{dateUI?.length > 1 ? dateUI.join(" – ") : dateUI}</BodyRegular16>
            <DateText>|</DateText>
            <GetStatus status={status} text={relativeTimeText} />
        </DescriptionDiv>
    );
};

export const GetStatus: FC<IGetStatus> = ({ status, text }) => {
    const textColor = status === "IN_PROGRESS" ? colors.success.text : colors.process.text;
    return <BodyRegular16 color={textColor}>{text}</BodyRegular16>;
};

export default EventsList;
