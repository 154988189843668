import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getHeadersWithAuthorization } from "../../requests/Headers";
import { HTTP_STATUS } from "../../constants";

interface IInitialState {
  loading: string;
}

const initialState: IInitialState = {
  loading: "idle",
};

export const activation = createSlice({
  name: "activation",
  initialState,
  reducers: {
    setLoadingInitial: (state) => {
      state.loading = "idle";
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchActivation.fulfilled, (state, action) => {
      // Add user to the state array
      state.loading = HTTP_STATUS.FULFILLED;
    });
    builder.addCase(fetchActivation.pending, (state, action) => {
      // Add user to the state array
      state.loading = HTTP_STATUS.PENDING;
    });
    builder.addCase(fetchActivation.rejected, (state, action) => {
      // Add user to the state array
      state.loading = HTTP_STATUS.REJECTED;
    });
  },
});

export const fetchActivation = createAsyncThunk(
  "tasks/fetchRegister",
  async (key: string, thunkAPI) => {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + `front/api/activate?key=${key}`,
      {
        headers: getHeadersWithAuthorization(),
      }
    );
    return response.data;
  }
);

// export const fetchRegister =
//   (data: IWeekendOfferState) => async (dispatch: Dispatch) => {
//     await WeekendOfferFromDataService.create(data)
//       .then((response) => {
//         dispatch(setItems(response.data));
//       })
//       .catch((er) => {
//         console.log(er);
//         dispatch(setError(true));
//       });
//   };

// Action creators are generated for each case reducer function
export const { setLoadingInitial } = activation.actions;

export default activation.reducer;
