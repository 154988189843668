import React, { useState } from "react";
import "../tasks-completed.css";
import TaskInfo from "./task-info";
import ToggleBtn from "./toggle-btn";
import { InactiveResponce } from "../../../services/api-services";
import styled from "styled-components";
import { colors } from "../../../constants/global-style-variables";
import { BodyRegular16, HeadingMedium30 } from "../../../components/title/title";

interface PointsDivProps {
    status: string;
}

const PointsDiv = styled.div<PointsDivProps>`
    border-radius: 8px;
    padding: 10px;
    background-color: ${(props: PointsDivProps) => (props.status === "COMPLETED" ? "#EFF7EF" : "#ffd9d9")};
    width: max-content;
`;

const TasksCompletedContainer = styled.div`
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const BlockContainer = styled.div`
    background-color: #ffffff;
    border-radius: 20px;
    padding: 40px;
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const BlockLeftSide = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;
const BlockRightSide = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
`;

const InfoContainer = styled.div<{ open: boolean }>`
    display: flex;
    flex-direction: column;
    cursor: ${props => (props.open ? "initial" : "default")};
    margin-top: ${props => (props.open ? "24px" : 0)};
    opacity: ${props => (props.open ? 1 : 0)};
    max-height: ${props => (props.open ? "100vh" : 0)};
    z-index: ${props => (props.open ? 9999 : -9999)};
    transition: all 0.5s;
`;

const TasksCompleted = ({ dataInactive }: { dataInactive: InactiveResponce[] }) => {
    // const [getWeekendOfferFinished, { data: dataInactive = [], isLoading }] = useLazyGetWeekendOfferFinishedQuery();

    // const dataInactive: InactiveResponce[] = [
    //     {
    //         recruitingOfferId: 0,
    //         recruitingOfferRequestId: 0,
    //         collectionId: 0,
    //         uuid: "",
    //         status: "",
    //         language: "",
    //         languageId: 0,
    //         comment: "",
    //         position: "Middle",
    //         timeToComplete: "",
    //         pointSum: "",
    //         maxPoint: "",
    //         taskCount: "",
    //         title: "serzhEVENT",
    //     },
    // ];

    // useEffect(() => {
    //     getWeekendOfferFinished();
    // }, [getWeekendOfferFinished]);

    const [open, setOpen] = useState({});

    const handleClick = (id: number) => {
        setOpen({ ...open, [id]: !open[id as keyof typeof open] });
    };

    // if (isLoading) {
    //     return <Loading />;
    // }

    return (
        <>
            <TasksCompletedContainer>
                {dataInactive.map((e: InactiveResponce) => {
                    console.log(open[e.recruitingOfferId as keyof typeof open]);
                    return (
                        <BlockContainer key={e.recruitingOfferId}>
                            <HeaderContainer>
                                <BlockLeftSide>
                                    <HeadingMedium30>{e.title}</HeadingMedium30>
                                    <BodyRegular16 color={colors.text.additional}>{e.position}</BodyRegular16>
                                </BlockLeftSide>
                                <BlockRightSide>
                                    <TaskInfo variant={e.status === "COMPLETED" ? "success" : "default"} />
                                    <ToggleBtn open={open[e.recruitingOfferId as keyof typeof open]} onClick={() => handleClick(e.recruitingOfferId)} />
                                </BlockRightSide>
                            </HeaderContainer>

                            {/*{open[e.recruitingOfferId as keyof typeof open] ? (*/}
                            <InfoContainer open={open[e.recruitingOfferId as keyof typeof open]}>
                                <p className={"right-side__check"}>Набрано баллов:</p>
                                <PointsDiv status={e.status}>{`${e.pointSum}/${e.maxPoint}`}</PointsDiv>
                                {e.comment ? (
                                    <>
                                        <p className={"right-side__check"}>Комментарий:</p>
                                        <div className={"success-block__comment-block"}>
                                            <p>{e.comment}</p>
                                        </div>
                                    </>
                                ) : null}
                            </InfoContainer>
                            {/*) : null}*/}
                        </BlockContainer>
                    );
                })}
            </TasksCompletedContainer>
        </>
    );
};

export default TasksCompleted;
