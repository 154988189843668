import React, { useState } from "react";
import IUserSignIn from "../../models/IUserSignIn";
import { useLoginMutation } from "../../services/auth-service";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Button, Checkbox, Input } from "@ui/ui-components";
import { colors } from "../../constants/global-style-variables";
import { toast } from "react-toastify";
import { setUsername } from "../../slices/slices";
import { Link } from 'react-router-dom';

const StyledInput = styled(Input)`
    font-family: Roboto Flex;
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    flex-direction: column;
    gap: 44px;
`;

const ParentDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 716px;
    height: 562px;
    background-color: ${() => colors.text.inverse};
    border-radius: 20px;
    padding: 0 81px;
`;

const Title = styled.p`
    font-family: Roboto;
    font-weight: 500;
    font-size: 48px;
    line-height: 54px;
    padding-top: 80px;
    color: ${() => colors.text.main};
`;

const ChildDiv = styled.div`
    flex: 1;
    padding-top: 48px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
`;

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;
`;

const StyledButton = styled(Button)`
    margin-top: 36px;
    color: ${() => colors.text.inverse} !important;
`;

const PRegister = styled.p`
    font-family: Roboto Flex;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
    text-align: left;
    color: ${() => colors.text.main} !important;
`;

interface IerrorValues {
    username: boolean;
    usernameText: string;
    password: boolean;
    passwordText: string;
}

const errorValues: IerrorValues = {
    username: false,
    usernameText: "",
    password: false,
    passwordText: "",
};

export const Login = () => {
    const [login, { isLoading }] = useLoginMutation();

    const [user, setUser] = useState<IUserSignIn>({
        username: "",
        password: "",
        rememberMe: false,
    });
    const [errors, setErrors] = useState(errorValues);
    const dispatch = useDispatch();

    const handleSubmit = async () => {
        if (!validate()) return false;
        try {
            const res = await login(user).unwrap();
            dispatch(setUsername(res.firstNameLastName));
        } catch (err: any) {
            if (err.status === 401) {
                toast.dark(`Не верно введен логин или пароль`);
            } else {
                console.log(err);
                toast.dark(`Ошибка при авторизации`);
            }
        }

        setUser({ password: "", rememberMe: false, username: "" });
    };

    const validate = () => {
        if (!user.username) {
            setErrors({
                ...errors,
                username: true,
                usernameText: "Пожалуйста, введите логин",
            });
            return false;
        }
        if (!user.password) {
            setErrors({
                ...errors,
                password: true,
                passwordText: "Пожалуйста, введите пароль",
            });
            return false;
        }
        setErrors({
            ...errors,
            username: false,
            usernameText: "",
            password: false,
            passwordText: "",
        });
        return true;
    };

    const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        setUser(prev => ({ ...prev, [name]: value }));
        if (value) {
            setErrors({
                ...errors,
                [name]: false,
                [`${name}Text`]: "",
            });
        }
    };

    const handleKeypress = (e: any) => {
        if (e.charCode === 13) {
            handleSubmit();
        }
    };

    return (
        <>
            <Container>
                <ParentDiv>
                    <Title>Вход</Title>
                    <ChildDiv>
                        <StyledInput onKeyPress={handleKeypress} value={user.username} name={"username"} onChange={handleChange} label={"Логин"} error={errors.username} fullWidth />
                        <StyledInput onKeyPress={handleKeypress} value={user.password} name={"password"} onChange={handleChange} label={"Пароль"} type={"password"} error={errors.password} fullWidth />
                        <Row>
                            <Checkbox label={"Запомнить меня"} />
                            <Link to={"/reset-password"}>Забыли пароль?</Link>
                        </Row>
                        <StyledButton disabled={isLoading} onClick={handleSubmit} label={"Войти"} />
                    </ChildDiv>
                </ParentDiv>
                <div>
                    <PRegister>
                        Ещё нет аккаунта? <Link to={"/register"}>Зарегистрируйтесь</Link>
                    </PRegister>
                </div>
            </Container>
        </>
    );
};

export default Login;
