export function Logout() {
  localStorage.clear();
}

export function getToken() {
  const token = localStorage.getItem("token");
  if (token !== null) {
    return token;
  }
  return null;
}

export function getWoKey() {
  const wokey = localStorage.getItem("wokey");
  if (wokey !== null) {
    return wokey;
  }
  return null;
}
