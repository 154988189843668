import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { fetchActivation } from "./activation-slice";
import { Alert, Stack } from "@mui/material";
import { HTTP_STATUS } from "../../constants";
import "./activation.css";

const Activation = () => {
  const params = useParams();
  const key = params.key || "";
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.activation.loading);

  useEffect(() => {
    dispatch(fetchActivation(key));
  }, [dispatch, key]);

  return (
    <div className={"content"}>
      <h1>Активация</h1>
      <Stack sx={{ width: "100%" }} spacing={2}>
        {loading === HTTP_STATUS.FULFILLED && (
          <Alert severity="success">
            <span className={"activation-text"}>
              Ваш аккаунт был успешно активирован. Пожалуйста, перейдите по
              ссылке в письме "Пришлашение на Weekend Offer"
            </span>
          </Alert>
        )}
        {loading === HTTP_STATUS.REJECTED && (
          <>
            <Alert severity="error">Произошла ошибка при активации</Alert>
          </>
        )}
      </Stack>
    </div>
  );
};

export default Activation;
