import { createSlice } from "@reduxjs/toolkit";
import { authApi } from "../../services/auth-service";

type AuthState = {
    token: string | null;
};
const slice = createSlice({
    name: "auth",
    initialState: {
        token: null,
    } as AuthState,
    reducers: {},
    extraReducers: builder => {
        builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
            localStorage.setItem("token", payload.id_token);
        });
    },
});

export default slice.reducer;

export const selectToken = () => localStorage.getItem("token");
