import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { selectToken } from "../pages/login/login.slice";
import { toast } from "react-toastify";
import { setUsername } from "../slices/slices";

const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    prepareHeaders: headers => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        // const token = (getState() as RootState).auth.token
        const token = selectToken();
        if (token) {
            headers.set("authorization", `Bearer ${token}`);
        }
        return headers;
    },
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result?.error?.status === 403) {
        toast.dark("это действие сейчас недоступно");
    }
    if (result?.error?.status === 401) {
        localStorage.clear();
        setUsername("");
    }

    return result;
};

export const apiSlice = createApi({
    tagTypes: ["EVENT"],
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({}),
});
