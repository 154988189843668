import React from "react";
import styled from "styled-components";

import StyledButton from "../../../components/button/styledButton";
import { Modal, ModalFooter, ModalSection } from "@ui/ui-components";
import AlertIcon from "../../../components/icons/alert-icon";
import { colors } from "../../../constants/global-style-variables";
import { SubheadingRegular24 } from "../../../components/title/title";

const ButtonDiv = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
`;

const AlertContainer = styled.div`
    height: 120px;
    width: 120px;
    border-radius: 100px;
    background: ${colors.warning.back};
    display: flex;
    justify-content: center;
    align-items: center;
`;

interface IProps {
    handleConfirm: () => void;
    handleCancel: () => void;
    refModal: any;
}

const EndModal = (props: IProps) => {
    const { refModal, handleCancel, handleConfirm } = props;

    return (
        <Modal ref={refModal}>
            <ModalSection>
                <AlertContainer>
                    <AlertIcon />
                </AlertContainer>
                <SubheadingRegular24>Вы действительно хотите завершить тестирование?</SubheadingRegular24>
                <SubheadingRegular24>Если вы завершите тестирование, результаты будут сохранены и отправлены на проверку</SubheadingRegular24>
            </ModalSection>
            <ModalFooter>
                <ButtonDiv>
                    <StyledButton variant={"outlined"} fullWidth onClick={handleCancel}>
                        Отмена
                    </StyledButton>
                    <StyledButton onClick={handleConfirm} variant={"danger"} fullWidth>
                        Да, завершить
                    </StyledButton>
                </ButtonDiv>
            </ModalFooter>
        </Modal>
    );
};

export default EndModal;
