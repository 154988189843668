import { apiSlice as api } from "../../../api/apiSlice";

export interface EventsResponse {
    id: string;
    uuid: string;
    comment: string;
    title: string;
    startDate: string;
    endDate: string;
}

export interface EventResponse {
    id: string;
    uuid: string;
    title: string;
    startDate: string;
    endDate: string;
    status: any;
    statusDescription: string;
    position: string;
    timeToComplete: number;
    createdDate: string;
    comment: any;
    createdBy: string;
    lastModifiedBy: string;
    lastModifiedDate: string;
    url: any;
    urlLabel: any;
    accessLevel: any;
    collectionDTOList: collectionDTOList[];
    recruitingStatus: string;
}

export interface collectionDTOList {
    id: string;
    title: string;
    level: any;
    tags: any[];
    maxScore: number;
    timeToComplete: number;
    tasksCount: number;
    status: string;
}

export interface Candidate {
    id: string;
    allTasks: number;
    maxPoints: number;
    allCases: number;
    userId: number;
    userLogin: string;
    status: string;
    startDate: string;
    endDate: string;
    duration: number;
    currentPoints: number;
    completedTasks: number;
    completedTestCases: number;
    recruitingOfferId: number;
    uuid: number;
    leaderLogin: string;
    requestId: number;
    collectionId: string;
    collectionTitle: string;
    userEmail: string;
}

interface List {
    id: number;
}

interface Request {
    id: string;
    collectionsList: List[];
}

interface RequestCandidate {
    id: string;
    collection: string;
    email: { email: string };
}

export const EventServices = api.injectEndpoints({
    endpoints: builder => ({
        getEvents: builder.query<EventsResponse[], boolean>({
            query: active => ({
                url: `/api/recruiting_offers?size=9999&page=0&active=${active}&sort=id,desc`,
            }),
        }),
        getEventById: builder.query<EventResponse, string>({
            query: id => ({
                url: `/api/recruiting_offer/${id}`,
            }),
            providesTags: ["EVENT"],
        }),
        getCandidatesByEvent: builder.query<Candidate[], string>({
            query: id => ({
                url: `/api/recruiting_offer/${id}/candidates`,
            }),
        }),
        getCollectionsByEvent: builder.query<any, string>({
            query: id => ({
                url: `/api/recruiting_offer/${id}/collections`,
            }),
        }),
        createEvent: builder.mutation<{ message: string }, any>({
            query: body => ({
                url: `/api/recruiting_offer`,
                method: "POST",
                body,
            }),
        }),
        deleteEvent: builder.mutation<void, string>({
            query: id => ({
                url: `/api/recruiting_offer/${id}`,
                method: "DELETE",
            }),
        }),
        addCollectionToEvent: builder.mutation<void, Request>({
            query: body => ({
                url: `/api/recruiting_offer/${body.id}/collections`,
                method: "POST",
                body: body.collectionsList,
            }),
        }),
        deleteCollectionFromEvent: builder.mutation<void, Request>({
            query: body => ({
                url: `/api/recruiting_offer/${body.id}/collections`,
                method: "DELETE",
                body: body.collectionsList,
            }),
        }),
        addCandidateToEvent: builder.mutation<void, RequestCandidate>({
            query: body => ({
                url: `/api/recruiting_offer/${body.id}/collection/${body.collection}/candidate`,
                method: "POST",
                body: body.email,
            }),
        }),
        startEvent: builder.mutation<void, string>({
            query: id => ({
                url: `/api/recruiting-offer/${id}/start`,
                method: "POST",
            }),
            invalidatesTags: ["EVENT"],
        }),
    }),
});

export const {
    useStartEventMutation,
    useLazyGetCollectionsByEventQuery,
    useAddCandidateToEventMutation,
    useAddCollectionToEventMutation,
    useDeleteCollectionFromEventMutation,
    useLazyGetEventsQuery,
    useLazyGetEventByIdQuery,
    useGetEventByIdQuery,
    useLazyGetCandidatesByEventQuery,
    useCreateEventMutation,
    useDeleteEventMutation,
} = EventServices;
