export const timeFormat = (time: number) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time - hours * 3600) / 60);
    let result = "";
    let lastCharOfHours: number = 1;

    if (hours.toString().length === 1) {
        lastCharOfHours = hours;
    } else {
        lastCharOfHours = parseInt(hours.toString().slice(-1));
    }
    if (lastCharOfHours >= 5) result += hours + " часов ";
    else if (lastCharOfHours >= 2) result += hours + " часа ";
    else if (lastCharOfHours === 1) result += hours + " час ";
    if (minutes >= 5) result += minutes + " минут";
    else if (minutes >= 2) result += minutes + " минуты";
    else if (minutes === 1) result += minutes + " минута";
    return result;
};
