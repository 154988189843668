import { configureStore, combineReducers, MiddlewareAPI, Middleware, isRejectedWithValue } from "@reduxjs/toolkit";
import tasksSliceReducer from "./pages/tasks/tasks-slice";
import tasksFilterReducer from "./pages/tasks/tasks-filter-slice";
import activationReducer from "./pages/activation/activation-slice";
import reducers from "./slices/slices";
import authReducer from "./pages/login/login.slice";
import { authApi } from "./services/auth-service";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["reducers"],
};

// const stateSyncConfig = {
//   whitelist: ["timer"],
//   blacklist: ["persist/PERSIST", "persist/REHYDRATE"],
// };
// const middlewares = [createStateSyncMiddleware(stateSyncConfig)];

const rootReducer = combineReducers({
    reducers: reducers,
    tasks: tasksSliceReducer,
    tasksFilter: tasksFilterReducer,
    activation: activationReducer,
    auth: authReducer,
    [authApi.reducerPath]: authApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(authApi.middleware),
});

// initMessageListener(store);
export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => next => action => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
        console.warn("We got a rejected action!");
        // toast.warn({ title: 'Async error!', message: action.error.data.message })
    }

    return next(action);
};
