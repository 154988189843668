import React, { useState } from "react";

import { Tab, TabContent, Tabs } from "@ui/ui-components";

import styled from "styled-components";
import EventsList from "./components/events/events-list";
import "dayjs/locale/ru";
import Loading from "../../components/loading/loading";
import { ActiveResponce, useGetWeekendOfferFinishedQuery, useGetWeekendOfferQuery } from "../../services/api-services";
import TasksCompleted from "../tasks/components/tasks-completed";
import { HeadingMedium30 } from "../../components/title/title";

const Main = styled.div`
    padding-top: 100px;
    display: flex;
    flex-direction: column;
`;

export const EmptyDataDiv = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 127px;
`;

export const EmptyDataText = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 34px;
    color: #8f8f8f;
`;

const TableDiv = styled.div`
    padding-top: 50px;
`;

const Events = () => {
    const { data = [], isFetching: isLoadingGetCollection } = useGetWeekendOfferQuery();
    const { data: dataFinished = [] } = useGetWeekendOfferFinishedQuery();
    const [value, setValue] = useState<number>(0);

    const handleChange = (e: number) => {
        setValue(e);
    };

    const getContent = () => {
        return (
            <Main>
                <HeadingMedium30 style={{ marginBottom: 44 }}>События</HeadingMedium30>
                <Tabs value={value} onChange={handleChange}>
                    <Tab>Текущие события</Tab>
                    <Tab>Завершенные события</Tab>
                </Tabs>
                <TabContent value={value} index={0}>
                    <Render emptyText={"Нет текущих событий"} data={data} isLoadingGetCollection={isLoadingGetCollection} />
                </TabContent>
                <TabContent value={value} index={1}>
                    <TasksCompleted dataInactive={dataFinished} />
                    {/*<Render emptyText={"Нет завершенных событий"} data={data} isLoadingGetCollection={isLoadingGetCollection} />*/}
                </TabContent>
            </Main>
        );
    };

    return getContent();
};

interface IEventsProps {
    isLoadingGetCollection: boolean;
    data: ActiveResponce[];
    emptyText: string;
}

const Render = (props: IEventsProps) => {
    const { isLoadingGetCollection, data, emptyText } = props;

    if (isLoadingGetCollection) {
        return <Loading />;
    }
    if (data.length > 0) {
        return (
            <TableDiv>
                <EventsList data={data} />
            </TableDiv>
        );
    }
    return (
        <EmptyDataDiv>
            <EmptyDataText>{emptyText}</EmptyDataText>
        </EmptyDataDiv>
    );
};

export default Events;
