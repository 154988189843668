import React from "react";
import styled from "styled-components";

const Div = styled.div`
    position: relative;
    width: 40px;
    height: 40px;
    background-color: #f2f2f2;
    border-radius: 100px;
    cursor: pointer;
`;

const RotateDiv = styled.div<{ open: boolean }>`
    transition: all 0.3s linear;
    top: ${(props: { open: boolean }) => (props.open ? "16px" : "13px")};
    transform: ${(props: { open: boolean }) => (props.open ? "rotate(135deg)" : "rotate(315deg)")};

    left: 15px;
    position: absolute;
    border: 1px solid black;
    width: 10px;
    height: 10px;
    border-top: none;
    border-right: none;
`;

interface IProps {
    open: boolean;
    onClick?: () => void;
}

const ToggleBtn = (props: IProps) => {
    const { open, onClick } = props;
    return (
        <Div onClick={onClick}>
            <RotateDiv open={open} />
        </Div>
    );
};

export default ToggleBtn;
