import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { BrowserRouter } from "react-router-dom";
import { persistor, store } from "./store";
import { Provider } from "react-redux";
import App from "./App";
import { PersistGate } from "redux-persist/integration/react";
import Loading from "./components/loading/loading";

const isDevEnv = process.env.REACT_APP_ENV === "K8";
if (!isDevEnv) {
    const handleKeyDown = (event: KeyboardEvent) => {
        // const keyCode = event.keyCode || event.which;
        const { shiftKey, ctrlKey, metaKey, code } = event;
        const disallowHotKeys = ["KeyC", "KeyI", "KeyJ"];

        if ((metaKey || ctrlKey) && disallowHotKeys.includes(code) && shiftKey) {
            return event.preventDefault();
        }

        if (code === 'F12') return event.preventDefault();

        // // Check if the pressed key is a non-text key or a functional key
        // if (
        //     (keyCode < 48 || keyCode > 90) && // Non-alphanumeric keys
        //     keyCode !== 13 && // Space key
        //     keyCode !== 32 && // Space key
        //     keyCode !== 8 && // Backspace key
        //     keyCode !== 46 && // Delete key
        //     keyCode !== 37 && // Left arrow key
        //     keyCode !== 39 && // Right arrow key
        //     keyCode !== 35 && // End key
        //     keyCode !== 36 && // Home key
        //     keyCode !== 9 && // Tab key
        //     keyCode !== 16 && // Shift key
        //     keyCode !== 17 && // Control key
        //     keyCode !== 18 && // Alt key
        //     keyCode !== 20 && // Caps Lock key
        //     keyCode !== 91 && // Left Command key (Mac)
        //     keyCode !== 93 && // Right Command key (Mac)
        //     keyCode !== 224 // Command key (Mac)
        // ) {
        //     // Prevent the default behavior of the key
        //     event.preventDefault();
        // }
    };
    // Добавляем обработчик событий keydown
    document.addEventListener("keydown", handleKeyDown);

    document.addEventListener("contextmenu", event => {
        event.preventDefault();
    });
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <Provider store={store}>
        <PersistGate loading={<Loading />} persistor={persistor}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </PersistGate>
    </Provider>
);
