import React from "react";

interface PropsType {
    size?: "small";
    color?: "fillDark";
}

const ChevronRight = (props: PropsType) => {
    const { size, color } = props;

    return (
        <svg width={`${size === "small" ? "8" : "10"}`} height={`${size === "small" ? "14" : "19"}`} viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.792894 0.792893C0.402369 1.18342 0.402369 1.81658 0.792894 2.20711L8.58579 10L0.792894 17.7929C0.402369 18.1834 0.402369 18.8166 0.792894 19.2071C1.18342 19.5976 1.81658 19.5976 2.20711 19.2071L10 11.4142C10.781 10.6332 10.781 9.36683 10 8.58579L2.20711 0.792893C1.81658 0.402369 1.18342 0.402369 0.792894 0.792893Z"
                fill={`${color === "fillDark" ? "#DCDCDC" : "#8F8F8F"}`}
            />
        </svg>
    );
};

export default ChevronRight;
