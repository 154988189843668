import React from "react";
import styled from "styled-components";

const Button = styled.button`
    width: ${(props: any) => (props.fullWidth ? "100%" : "auto")};

    color: ${(props: { variant: "outlined" | "primary" | "danger"; disabled: boolean; status: boolean }) => {
        if (props.status) {
            return "#1F1F1F";
        }
        if (props.disabled) {
            return "#8f8f8f";
        } else {
            return props.variant === "danger" ? "#ffffff" : props.variant === "outlined" ? "#2BA9D0" : "#ffffff";
        }
    }};
    border: ${(props: { variant: "outlined" | "primary" | "danger"; disabled: boolean; status: boolean }) => {
        if (props.status) {
            return "none";
        }
        if (props.disabled) {
            return "#f2f2f2";
        }
        return props.variant === "danger" ? "1px solid #FF7373" : "1px solid #2ba9d0";
    }};
    border-radius: 12px;
    padding: 15px;
    //height: 40px;
    outline: ${(props: { variant: "outlined" | "primary" | "danger" }) => (props.variant === "danger" ? "1px solid #FF7373" : "1px solid #2ba9d0")};
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    cursor: ${(props: { disabled: boolean }) => {
        if (props.disabled) {
            return "auto";
        } else {
            return "pointer";
        }
    }};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    outline: none;
    background-position: center;
    //transition: background 2s;
    background-color: ${(props: { variant: "outlined" | "primary" | "danger"; disabled: boolean; status: boolean }) => {
        if (props.status) {
            return "#DCDCDC";
        }
        if (props.disabled) {
            return "#f2f2f2";
        } else {
            return props.variant === "danger" ? "#FF7373" : props.variant === "outlined" ? "#ffffff" : "#2BA9D0";
        }
    }};
    //:hover {
    //    background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
    //}
    //:active {
    //    background-color: #6eb9f7;
    //    background-size: 100%;
    //    transition: background 0s;
    //}
`;

interface Props {
    children?: any;
    rest?: any;
    onClick?: (event: React.FormEvent<HTMLFormElement>) => void;
    fullWidth?: boolean;
    variant?: "outlined" | "primary" | "danger";
    disabled?: boolean;
    status?: boolean;
}

const StyledButton = ({ status, children, onClick, fullWidth, variant, rest, disabled }: Props) => {
    return (
        <Button status={status} disabled={disabled} variant={variant} fullWidth={fullWidth} onClick={onClick} {...rest}>
            {children}
        </Button>
    );
};

export default StyledButton;
