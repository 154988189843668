import React from "react";

const TimerIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 5C11 4.44772 10.5523 4 10 4C9.44771 4 9 4.44772 9 5V9.69098C9 10.2591 9.321 10.7785 9.82918 11.0326L13.5528 12.8944C14.0468 13.1414 14.6474 12.9412 14.8944 12.4472C15.1414 11.9532 14.9412 11.3526 14.4472 11.1056L11 9.38197V5Z"
                fill="#1F1F1F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10Z"
                fill="#1F1F1F"
            />
        </svg>
    );
};

export default TimerIcon;
