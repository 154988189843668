import React from "react";
import { EventResponse } from "../../api/events";
import { DateAndStatus } from "../events/events-list";
import { Button } from "@ui/ui-components";
import styled from "styled-components";
import { colors } from "../../../../constants/global-style-variables";

import CheckIcon from "../../../../components/icons/check-icon";
import { HeadingMedium30 } from "../../../../components/title/title";

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Span = styled.span`
    border-radius: 8px;
    padding: 8px 16px;
    background: ${colors.element.fillLight};
    display: flex;
    gap: 8px;
    align-items: center;
    width: fit-content;
`;

const InfoContainer = styled.div`
    display: flex;
    gap: 12px;
    flex-direction: column;
`;

const ButtonsContainer = styled.div`
    width: 276px;
`;

interface IHeader {
    data: EventResponse;
    handleStartEvent: () => void;
    eventDateStatus: boolean;
}

const Header = (props: IHeader) => {
    const { data, handleStartEvent, eventDateStatus } = props;

    const handleStart = () => {
        handleStartEvent();
    };

    return (
        <HeaderContainer>
            <InfoContainer>
                {eventDateStatus ? <HeadingMedium30>{data.title}</HeadingMedium30> : <HeadingMedium30 style={{ color: colors.text.additional }}>{data.title}</HeadingMedium30>}

                <DateAndStatus startDate={data.startDate} endDate={data.endDate} status={data.recruitingStatus} />
                <Span>
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.00008 3.00001C6.34322 3.00001 5.00008 4.34315 5.00008 6C5.00008 7.65686 6.34322 9 8.00008 9C9.65693 9 11.0001 7.65686 11.0001 6C11.0001 4.34315 9.65693 3.00001 8.00008 3.00001ZM6.33341 6C6.33341 5.07953 7.0796 4.33334 8.00008 4.33334C8.92055 4.33334 9.66674 5.07953 9.66674 6C9.66674 6.92048 8.92055 7.66667 8.00008 7.66667C7.0796 7.66667 6.33341 6.92048 6.33341 6Z"
                            fill="#8F8F8F"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.00008 0.666672C3.83807 0.666672 1.32348 3.39818 0.337986 5.16421C0.0474943 5.68477 0.0474943 6.31524 0.337986 6.8358C1.32348 8.60183 3.83807 11.3333 8.00008 11.3333C12.1621 11.3333 14.6767 8.60183 15.6622 6.8358C15.9527 6.31524 15.9527 5.68477 15.6622 5.16421C14.6767 3.39818 12.1621 0.666672 8.00008 0.666672ZM1.5023 5.81393C2.34498 4.30384 4.48563 2.00001 8.00008 2.00001C11.5145 2.00001 13.6552 4.30384 14.4979 5.81393C14.563 5.93066 14.563 6.06935 14.4979 6.18608C13.6552 7.69617 11.5145 10 8.00008 10C4.48563 10 2.34498 7.69617 1.5023 6.18608C1.43717 6.06935 1.43717 5.93066 1.5023 5.81393Z"
                            fill="#8F8F8F"
                        />
                    </svg>
                    <p>{data.accessLevel === "notall" ? "Ограниченный доступ" : "Доступно всем"}</p>
                </Span>
            </InfoContainer>
            <ButtonsContainer>
                <CheckEventStatus data={data} handleStart={handleStart} />
            </ButtonsContainer>
        </HeaderContainer>
    );
};

const IsAcceptEventContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 11px;
    //styleName: body/medium 16;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
`;

const CheckEventStatus = ({ data, handleStart }: { data: EventResponse; handleStart: () => void }) => {
    if (data.recruitingStatus.toLowerCase() === "in_progress") {
        return <IsAcceptEvent />;
    }
    return <Button onClick={handleStart} fullWidth label={"Принять участие"} />;
};

const IsAcceptEvent = () => {
    return (
        <IsAcceptEventContainer>
            <CheckIcon color={colors.success.text} />
            <p>Вы участвуете в событии</p>{" "}
        </IsAcceptEventContainer>
    );
};

export default Header;
