import React, { useEffect, useRef } from "react";
import Timer from "../timer/timer";
import styled from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setResetState } from "../../slices/slices";
import EndModal from "../../pages/tasks/components/end-modal";
import { useLazyFinishCollectionQuery, useLazyGetCurrentTimeQuery } from "../../services/task-services";

import { Button } from "@ui/ui-components";
import TimerIcon from "../icons/timer-icon";
import { SubheadingRegular24 } from "../title/title";
import ChevronLeft from "../icons/chevron-left";

const HeaderTasks = styled.div`
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    align-items: center;
    height: 75px;
`;

const TimerDiv = styled.div`
    margin-right: 24px;
    margin-left: auto;
    background-color: #ededed;
    padding: 4px 16px 4px 4px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 15px;
`;

const PHeader = styled.p`
    font-size: 30px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0px;
    text-align: left;
`;

const SvgDiv = styled.div`
    margin-left: 18px;
    display: flex;
    align-items: center;
`;

const TimerTextDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
`;

const HeaderLink = styled(Link)`
    text-decoration: none;
`;

const StyledButton = styled(Button)`
    //styleName: subheading/medium 20;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
`;

const LinkContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 30px;
`;

interface IProps {
    link?: boolean;
    eventId: string;
    collectionId: string;
}

const Header = (props: IProps) => {
    const { eventId, collectionId } = props;
    const [finishCollection, { isSuccess: isSuccessFinish, isError: isErrorFinish }] = useLazyFinishCollectionQuery();
    const [getCurrentTime, { data: time }] = useLazyGetCurrentTimeQuery();

    const title = useSelector((state: any) => state.reducers.title);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ref: any = useRef(null);

    useEffect(() => {
        if (collectionId) {
            getCurrentTime({ eventId, collectionId });

            const interval = setInterval(() => {
                getCurrentTime({ eventId, collectionId });
            }, 1000 * 60);

            return () => {
                clearTimeout(interval);
            };
        }
    }, [collectionId, eventId, getCurrentTime]);

    useEffect(() => {
        if (isSuccessFinish) {
            dispatch(setResetState());
            navigate("/");
        }
    }, [isSuccessFinish, isErrorFinish, navigate, dispatch, collectionId]);

    const handleConfirm = () => {
        if (collectionId) {
            finishCollection({ offerId: eventId, collectionId });
        }
    };

    const handleCancel = () => {
        ref.current && ref.current.close();
    };

    const handleOpenModal = () => {
        ref.current && ref.current.showModal();
    };

    if (!collectionId) {
        return null;
    }

    const Title = () => {
        const location = useLocation();
        if (location.pathname === "/tasks-list") {
            return (
                <div>
                    <PHeader>{title}</PHeader>
                </div>
            );
        }

        return (
            <HeaderLink to={"/tasks-list"}>
                <LinkContainer>
                    <ChevronLeft />
                    <SubheadingRegular24>К списку задач</SubheadingRegular24>
                </LinkContainer>
            </HeaderLink>
        );
    };

    return (
        <>
            <EndModal refModal={ref} handleCancel={handleCancel} handleConfirm={handleConfirm} />
            <HeaderTasks>
                <Title />
                <TimerDiv>
                    <SvgDiv>
                        <TimerIcon />
                    </SvgDiv>
                    <TimerTextDiv>
                        <SubheadingRegular24>{time && <Timer time={time} eventId={eventId} collectionId={collectionId} />}</SubheadingRegular24>
                    </TimerTextDiv>
                </TimerDiv>
                <div>
                    <StyledButton label={"Завершить тест"} variant={"danger"} onClick={handleOpenModal} size={"small"} />
                </div>
            </HeaderTasks>
        </>
    );
};

const TasksHeader = React.memo(Header);

export default TasksHeader;
