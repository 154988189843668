import React, { useState } from "react";
import ISolutionResponse from "../../../models/ISolutionResponse";
import styled from "styled-components";
import { useLazyGetResultAnalyzedQuery } from "../../../services/task-services";
import Loading from "../../../components/loading/loading";
import { useSelector } from "react-redux";

const SvgDiv = styled.div`
    padding-right: 10px;
`;

const SolutionDiv = styled.div`
    :hover {
        background-color: #ededed;
    }
    border-radius: 5px;
    padding: 10px;
    inset: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: ${(props: { status: string }) => {
        if (props.status === "Accepted") {
            return "#60AE5E";
        }
        if (props.status === "Started" || props.status === "Processing") {
            return "#DCA437";
        }
        return "#FF7373";
    }};
`;

const SpanTestCaseIndex = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    background-color: #ededed;
    border-radius: 100px;
    padding: 10px;
    width: 30px;
    height: 30px;
`;

export const ResultDivInfo = styled.div`
    display: flex;
    justify-content: center;
    color: #8f8f8f;
    padding-top: 100px;
`;

const MainDiv = styled.div`
    height: 656px;
    overflow: auto;
`;

const SolutionResultInner = styled.pre`
  padding: 10px;
  background: #f8f8f8;
  border-radius: 6px;
`;

const SolutionsComp = (taskId: string, fetching: any, setFetching: any, solutions: ISolutionResponse[], setCollapse: any, collapse: any, collapseChild: any, setCollapseChild: any) => {
    const [getResult, { isFetching }] = useLazyGetResultAnalyzedQuery();
    const submitTaskId = useSelector((state: any) => state.reducers.submitTaskId);
    const [solutionTestCases, setSolutionTestCases] = useState<Record<number, string>>();

    // useEffect(() => {
    //     const obj =
    //         solutions &&
    //         solutions.reduce(function (accumulator: any, currentValue) {
    //             accumulator[currentValue.id] = false;
    //             return accumulator;
    //         }, {});
    //     // const tmp = solutions.map((e) => ({ [e.id]: false }));
    //     setCollapse(obj);
    // }, [solutions, setCollapse]);

    const handleClick = (solutionId: number) => {
        setCollapse({ ...collapse, [solutionId]: !collapse[solutionId] });
        handleGetResult(solutionId);
    };

    const handleGetResult = async (solutionId: number) => {
        if (!collapse[solutionId]) {
            const testCaseResult = await getResult(solutionId).unwrap();
            setSolutionTestCases(state => (state = { ...state, [solutionId]: testCaseResult }));
        }
    };

    if (solutions.length === 0 && submitTaskId[taskId]) {
        return (
            <>
                <ResultDivInfo>Результат в обработке...</ResultDivInfo>
                <Loading />
            </>
        );
    }

    const copySolutions = [...solutions];
    return (
        <MainDiv>
            {isFetching ? (
                <Loading />
            ) : (
                copySolutions &&
                copySolutions
                    .sort((a, b) => a.id - b.id)
                    .map((solution: ISolutionResponse, index) => {
                        return (
                            <div key={solution.id}>
                                <SolutionDiv onClick={() => handleClick(solution.id)} key={solution.id} status={solution.status}>
                                    <SolutionStatus status={solution.status} index={index} />
                                </SolutionDiv>
                                {collapse[solution.id] ? (
                                    <SolutionResultInner>
                                        <code>{solutionTestCases ? solutionTestCases[solution.id] : null}</code>
                                    </SolutionResultInner>
                                ) : null}
                            </div>
                        );
                    })
            )}
        </MainDiv>
    );
};

const SolutionStatus = (props: { status: string; index: number }) => {
    const { status, index } = props;

    if (status === "Accepted") {
        return (
            <>
                <SvgDiv>
                    <svg width="10" height="12" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M17.7065 0.292893C18.0971 0.683417 18.0971 1.31658 17.7065 1.70711L6.70655 12.7071C6.51901 12.8946 6.26466 13 5.99944 13C5.73423 13 5.47987 12.8946 5.29234 12.7071L0.578049 7.99282C0.187525 7.6023 0.187525 6.96913 0.578049 6.57861C0.968574 6.18808 1.60174 6.18808 1.99226 6.57861L5.99944 10.5858L16.2923 0.292893C16.6829 -0.0976311 17.316 -0.0976311 17.7065 0.292893Z"
                            fill="#60AE5E"
                        />
                    </svg>
                </SvgDiv>
                <span>Тест успешен</span>
                <SpanTestCaseIndex>{index + 1}</SpanTestCaseIndex>
            </>
        );
    }
    if (status === "Started" || status === "Processing") {
        return (
            <>
                <SvgDiv>
                    <svg width="10" height="12" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M11.5 5C11.5 4.44772 11.0523 4 10.5 4C9.94771 4 9.5 4.44772 9.5 5V9.69098C9.5 10.2591 9.821 10.7785 10.3292 11.0326L14.0528 12.8944C14.5468 13.1414 15.1474 12.9412 15.3944 12.4472C15.6414 11.9532 15.4412 11.3526 14.9472 11.1056L11.5 9.38197V5Z"
                            fill="#DCA437"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M20.5 10C20.5 15.5228 16.0228 20 10.5 20C4.97715 20 0.5 15.5228 0.5 10C0.5 4.47715 4.97715 0 10.5 0C16.0228 0 20.5 4.47715 20.5 10ZM18.5 10C18.5 14.4183 14.9183 18 10.5 18C6.08172 18 2.5 14.4183 2.5 10C2.5 5.58172 6.08172 2 10.5 2C14.9183 2 18.5 5.58172 18.5 10Z"
                            fill="#DCA437"
                        />
                    </svg>
                </SvgDiv>
                <span>Тест в обработке</span>
                <SpanTestCaseIndex>{index + 1}</SpanTestCaseIndex>
            </>
        );
    }

    return (
        <>
            <SvgDiv>
                <svg width="10" height="10" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L8.41421 7L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L7 8.41421L1.70711 13.7071C1.31658 14.0976 0.683417 14.0976 0.292893 13.7071C-0.0976311 13.3166 -0.0976311 12.6834 0.292893 12.2929L5.58579 7L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
                        fill="#FF7373"
                    />
                </svg>
            </SvgDiv>
            <span>Тест провален</span>
            <SpanTestCaseIndex>{index + 1}</SpanTestCaseIndex>
        </>
    );
};

const SecondTabMemo = ({ solutions, taskId }: { solutions: ISolutionResponse[]; taskId: string }) => {
    const [collapse, setCollapse] = useState<object>([]);
    const [fetching, setFetching] = useState<object>([]);
    const [collapseChild, setCollapseChild] = useState<object>([]);

    return <div style={{ padding: 20 }}>{SolutionsComp(taskId, fetching, setFetching, solutions, setCollapse, collapse, collapseChild, setCollapseChild)}</div>;
};

const SecondTab = React.memo(SecondTabMemo);

export default SecondTab;
