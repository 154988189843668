import React from "react";

const CheckIcon = ({ color }: { color?: string }) => {
    return (
        <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.7065 0.292893C18.0971 0.683417 18.0971 1.31658 17.7065 1.70711L6.70655 12.7071C6.51901 12.8946 6.26466 13 5.99944 13C5.73423 13 5.47987 12.8946 5.29234 12.7071L0.578049 7.99282C0.187525 7.6023 0.187525 6.96913 0.578049 6.57861C0.968574 6.18808 1.60174 6.18808 1.99226 6.57861L5.99944 10.5858L16.2923 0.292893C16.6829 -0.0976311 17.316 -0.0976311 17.7065 0.292893Z"
                fill={color}
            />
        </svg>
    );
};

export default CheckIcon;
