import React, { FC, ReactNode, useState } from "react";
import styled from "styled-components";
import InputMask, { Props } from "react-input-mask";
import { colors } from "../../constants/global-style-variables";
import { Button, Checkbox, Input } from "@ui/ui-components";
import { RegisterRequest, useRegisterMutation } from "../../services/register-services";
import { Backdrop, CircularProgress } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Container = styled.div`
    width: 716px;
    margin: 50px auto;
`;

const Main = styled.div`
    border-radius: 20px;
    padding: 80px 81px 80px 81px;
    background: ${colors.text.inverse};
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const P = styled.p`
    font-family: Roboto, sans-serif;
    font-size: 48px;
    font-weight: 500;
    line-height: 54px;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 48px;
`;

const CheckboxDiv = styled.div`
    display: flex;
    margin-top: 38px;
    margin-bottom: 50px;
`;

const CheckboxP = styled.p`
    font-family: Roboto Flex, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
`;

const RegisterQuestionText = styled.p`
    font-family: Roboto Flex;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    color: ${colors.text.main};
    text-align: center;
    padding-top: 44px;
`;

const StyledLink = styled(Link)`
    color: ${colors.action.primary};
`;

interface IerrorValues {
    email: boolean;
    emailText: string;
    password: boolean;
    passwordText: string;
    repeatPassword: boolean;
    repeatPasswordText: string;
    login: boolean;
    loginText: string;
}

const errorValues: IerrorValues = {
    email: false,
    emailText: "",
    password: false,
    passwordText: "",
    repeatPassword: false,
    repeatPasswordText: "",
    login: false,
    loginText: "",
};

const initialFormState = {
    lastName: "",
    firstName: "",
    email: "",
    password: "",
    phoneNumber: "",
    login: "",
    repeatPassword: "",
};

type TInputMaskCorrect = Omit<Props, 'children'> & { children?: (props: any) => JSX. Element };
const InputMaskPhone: FC<TInputMaskCorrect> = ( { children, ...props }) => {
    const child = children as ReactNode;
    return <InputMask children={child} {...props} />
}

const Register = () => {
    const [formState, setFormState] = useState<RegisterRequest>(initialFormState);
    const [errors, setErrors] = useState(errorValues);
    const [register, { isLoading }] = useRegisterMutation();
    const [checked, setChecked] = useState(false);
    const navigate = useNavigate();

    const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        if (value) {
            setErrors({
                ...errors,
                [name]: false,
                [`${name}Text`]: "",
            });
        }

        setFormState(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async () => {
        if (!validate()) return;
        if (!validateEmail()) return;
        if (!validatePassword()) return;
        try {
            await register(formState).unwrap();
            toast("Вы успешно зарегистрировались");
            navigate("/");
        } catch (e: any) {
            if (e.data.message === "already exists") {
                toast.dark("Пользователь с email: " + formState.email + " или логином: " + formState.login + " уже зарегистрирован!");
            } else {
                toast.dark("Возникли ошибки при регистрации, попробуйте зарегистрироваться позже");
            }
        }
    };

    const handleCheck = () => {
        setChecked(!checked);
    };

    const validatePassword = () => {
        const pass = formState.password;

        if (!pass) {
            setErrors({
                ...errors,
                password: true,
                passwordText: "Пожалуйста, введите пароль",
            });
            return false;
        }

        const passwordText =
            "Длина пароля не должна быть меньше 8 символов\nПароль должен содержать как минимум одну букву в верхнем регистре\nПароль должен содержать как минимум одну букву в нижнем регистре\nПароль должен содержать как минимум одну цифру\nПароль должен содержать как минимум один спецсимвол\nСпецсимволы: ! @ # $ % ^ & * + _ -";

        if (pass.length < 8) {
            setErrors({
                ...errors,
                password: true,
                passwordText,
            });
            return false;
        }

        const splittedPass = pass.replace(/[\d!@#$%^&*+_-]/g, "").split("");

        if (!splittedPass.some(e => e === e.toUpperCase())) {
            setErrors({
                ...errors,
                password: true,
                passwordText,
            });
            return false;
        }

        if (!splittedPass.some(e => e === e.toLowerCase())) {
            setErrors({
                ...errors,
                password: true,
                passwordText,
            });
            return false;
        }

        if (!/\d/g.test(pass)) {
            setErrors({
                ...errors,
                password: true,
                passwordText,
            });
            return false;
        }

        if (!/[!@#$%^&*+_-]/g.test(pass)) {
            setErrors({
                ...errors,
                password: true,
                passwordText,
            });
            return false;
        }

        if (pass !== formState.repeatPassword) {
            setErrors({
                ...errors,
                repeatPassword: true,
                repeatPasswordText: "Пароли не совпадают",
            });
            return false;
        }

        return true;
    };

    const validate = () => {
        let isError = true;
        if (!formState.login) {
            setErrors({
                ...errors,
                login: true,
                loginText: "Пожалуйста, введите логин",
            });
            isError = false;
        }
        if (!formState.email) {
            setErrors({
                ...errors,
                email: true,
                emailText: "Пожалуйста, введите email",
            });
            isError = false;
        }
        if (!isError) return false;
        setErrors({
            ...errors,
            login: false,
            loginText: "",
            password: false,
            passwordText: "",
            email: false,
            emailText: "",
        });
        return true;
    };

    const validateEmail = (): boolean => {
        const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (formState.email.match(validRegex)) {
            return true;
        } else {
            setErrors({
                ...errors,
                email: true,
                emailText: "Не корректный email",
            });
            return false;
        }
    };

    return (
        <>
            <Backdrop sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Container>
                <Main>
                    <P>Регистрация</P>
                    <Input onChange={handleChange} name={"lastName"} value={formState.lastName} label={"Фамилия"} fullWidth />
                    <Input onChange={handleChange} name={"firstName"} value={formState.firstName} label={"Имя"} fullWidth />
                    <Input onChange={handleChange} name={"login"} value={formState.login} label={"Логин"} fullWidth error={errors.login} />
                    <Input
                        style={{ marginBottom: errors.email ? 10 : 0 }}
                        onChange={handleChange}
                        name={"email"}
                        value={formState.email}
                        label={"E-mail"}
                        fullWidth
                        error={errors.email}
                        helperText={errors.emailText}
                    />
                    <InputMaskPhone mask={"+7 (999) 999-99-99"} onChange={handleChange} name={"phoneNumber"} value={formState.phoneNumber}>
                        {(inputProps: any) => <Input {...inputProps} label={"Номер телефона"} fullWidth />}
                    </InputMaskPhone>
                    <Input
                        style={{ marginBottom: errors.password ? (!formState.password ? 10 : 95) : 0 }}
                        error={errors.password}
                        helperText={errors.passwordText}
                        onChange={handleChange}
                        type={"password"}
                        name={"password"}
                        value={formState.password}
                        label={"Пароль"}
                        fullWidth
                    />
                    <Input
                        type={"password"}
                        error={errors.repeatPassword}
                        helperText={errors.repeatPasswordText}
                        onChange={handleChange}
                        name={"repeatPassword"}
                        value={formState.repeatPassword}
                        label={"Повторить пароль"}
                        fullWidth
                    />
                    <CheckboxDiv>
                        <Checkbox checked={checked} onChange={handleCheck} />
                        <CheckboxP>Даю согласие на сбор и обработку персональных данных</CheckboxP>
                    </CheckboxDiv>
                    <Button disabled={!checked} onClick={handleSubmit} label={"Зарегистрироваться"} />
                </Main>
                <RegisterQuestionText>
                    Уже зарегистрированы? <StyledLink to={"/"}>Войти</StyledLink>
                </RegisterQuestionText>
            </Container>
        </>
    );
};

export default Register;
