import styled from "styled-components";
import { colors } from "../../constants/global-style-variables";

//CAPTION
export const CaptionRegular12 = styled.p<{ color?: string }>`
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${props => (props.color ? props.color : colors.text.main)};
`;

//BODY
export const BodyRegular16 = styled.p<{ color?: string }>`
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${props => (props.color ? props.color : colors.text.main)};
`;

export const BodyRegular20 = styled.p<{ color?: string }>`
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: ${props => (props.color ? props.color : colors.text.main)};
`;

export const BodyMedium16 = styled.p<{ color?: string }>`
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${props => (props.color ? props.color : colors.text.main)};
`;

//SUBHEADING
export const SubheadingRegular24 = styled.p<{ color?: string }>`
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: ${props => (props.color ? props.color : colors.text.main)};
`;

export const SubheadingMedium20 = styled.p<{ color?: string }>`
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: ${props => (props.color ? props.color : colors.text.main)};
`;

//HEADING
export const HeadingMedium30 = styled.p<{ color?: string }>`
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 34px;
    color: ${props => (props.color ? props.color : colors.text.main)};
`;

export const HeadingMedium50 = styled.p<{ color?: string }>`
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 54px;
    color: ${props => (props.color ? props.color : colors.text.main)};
`;
