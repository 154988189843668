import React, { useEffect } from "react";
import { useTimer } from "react-timer-hook";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLazyFinishCollectionQuery } from "../../services/task-services";
import { setResetState } from "../../slices/slices";

interface ITimer {
    wouuid?: string;
    collectionId: string;
    eventId: string;
    timeToComplete?: number;
    time: number;
}

const TimerComp = (props: ITimer) => {
    const { time, collectionId, eventId } = props;
    const [finishCollection, { isSuccess: isSuccessFinish }] = useLazyFinishCollectionQuery();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const expiryTimestamp = new Date();

    expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + time);

    useEffect(() => {
        if (isSuccessFinish) {
            dispatch(setResetState());
            navigate("/task-complete");
        }
    // Игнорируем ошибку потому что она бесполезна
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccessFinish]);

    const { seconds, minutes, hours } = useTimer({
        expiryTimestamp,
        onExpire: () => {
            finishCollection({ offerId: eventId, collectionId });
        },
    });

    return (
        <>
            <span>{hours > 9 ? hours : `0${hours}`}</span>:<span>{minutes > 9 ? minutes : `0${minutes}`}</span>:<span>{seconds > 9 ? seconds : `0${seconds}`}</span>
        </>
    );
};

const Timer = React.memo(TimerComp);
export default Timer;
